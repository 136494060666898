import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import NotFoundImage from '../../images/404.gif';

interface NotFoundProps {}

interface NotFoundState {}

class NotFound extends React.Component<NotFoundProps, NotFoundState> {

    componentDidMount () {
        window.scrollTo(0, 0);
    }
  
    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={1} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"not-found"} />
                        </Col>
                        <Col xs={10} sm={8}>
                            <Container fluid style={{backgroundImage: `url(${NotFoundImage})`, backgroundSize: "cover", overflow: "hidden", minHeight: "500px"}}>
                                <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                    <h5 style={{fontSize: "60px", textShadow: "2px 2px #000000"}}> 404 </h5>
                                    <p style={{fontSize: "40px", textShadow: "2px 2px #000000"}}> NOT FOUND </p>
                                </div>  
                            </Container>
                        </Col>
                        <Col xs={1} sm={2}>
                            <MenuComponent isMobile={false}/>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {NotFound};