import * as React from 'react';
import { ProjectBean } from '../../beans/projectBean';
import { stopFixedPositionAt } from '../../utils/checkFixedPosition';
import { Link } from "react-router-dom";
import { ProjectServiceManager } from '../../services/projectServiceManager';
import { ProjectsViewFormat } from '../../beans/projectsViewFormat';
import { isMobileOnly } from 'react-device-detect';

interface ProjectsComponentProps {
  projectService : ProjectServiceManager;
  projectsView : ProjectsViewFormat;
}

interface ProjectsComponentState {
  greaterThan : boolean;
  lessThan : boolean;
  projects : Map<string, ProjectBean>;
  projectsActive : Map<string, boolean>;
  translateX: number;
  translateY: number;
}

class ProjectsComponent extends React.Component<ProjectsComponentProps, ProjectsComponentState> {

    formatterMonths = new Intl.DateTimeFormat('en', { month: 'short' });
    converter = require('number-to-words');

    constructor(props) {
      super(props);
      this.state = {
          greaterThan: false,
          lessThan: false,
          projects: new Map<string, ProjectBean>(),
          projectsActive: new Map<string, boolean>(),
          translateX: 0,
          translateY: 0
      }
    }
    
    componentDidMount() {
      window.scrollTo(0, 0);
      this.setState({projects: this.props.projectService.loadProjects()});
      this.checkActiveDiv();
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('touchmove', this.handleScroll);
      this.activeSmoothScroll();
    }
  
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('touchmove', this.handleScroll);
        this.disableSmoothScroll();
    }
  
    activeSmoothScroll() {
      $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        let hrefAttr = $['attr'](this, 'href');
        
          $('html, body').animate({
            scrollTop: $(hrefAttr)?.offset()?.top
          }, 400);
      });
    }
  
    disableSmoothScroll() {
      $(document).off('click', 'a[href^="#"]');
    }
  
    checkPosition = () => {
      let spread = 600;
      if (isMobileOnly) {
        spread = 200;
      }
      let myMap = stopFixedPositionAt(".project-menu", "footer", spread);
      this.setState({greaterThan: myMap?.greaterThan, lessThan: myMap?.lessThan});
    }
  
    checkActiveDiv = () => {
      var scrollTop = $(document).scrollTop() as number;
      var spread = 300;
  
      let projectsArray : string[] = Array.from(this.state.projects.keys());
  
      projectsArray.forEach( (item, index) => {
        let divIdName : string = "#" + item;
        let divHeight : number = $(divIdName).height() as number;
        let divTop : number = $(divIdName).offset()?.top as number;
  
        if (divTop <= scrollTop + spread && divTop + divHeight > scrollTop + spread) {
          this.state.projectsActive.set(item, true);
        } else {
          this.state.projectsActive.set(item, false);
        }
      })
    }
  
    handleScroll = (event) => {
      this.checkPosition();
      this.checkActiveDiv();
    }

    setTranslatePosition(clientX : number, clientY : number) {
      let centerX = window.innerWidth / 2;
      let centerY = window.innerHeight / 2;
      this.setState({translateX: centerX - clientX, translateY: centerY - clientY});
    }

    handleMouseMove = (event : React.MouseEvent) => {
      this.setTranslatePosition(event.clientX, event.clientY);
    }

    handleTouchMove = (event : React.TouchEvent) => {
      this.setTranslatePosition(event.targetTouches[0].clientX, event.targetTouches[0].clientY);
    }
  
    render() {
      let projectMenuClassName = "project-menu";
      if (this.state.greaterThan){
        projectMenuClassName += " project-menu-absolute";
      } else if (this.state.lessThan){
        projectMenuClassName += " project-menu-fixed";
      }
      let projectsArray : string[] = Array.from(this.state.projects.keys());
      let angleSpread = 360 / (projectsArray.length - 1)
      let radius = 600;
      if (this.props.projectsView === ProjectsViewFormat.Grid) {
        return (
          <div onMouseMove={this.handleMouseMove} onTouchMove={this.handleTouchMove} className="grid-relative-container .grid-main">
            <div className="grid-module-content grid-unit-wrapper">
              <div className="grid-unit-copy-wrapper">
                <div className="grid-unit-canvas-container">
                  <div style={{willChange: "transform", opacity: 1, transform: `translate(${this.state.translateX}px, ${this.state.translateY}px)`}} className="grid-unit-canvas-wrapper">
                    {projectsArray
                      .sort( (a,b) => {
                        let valueItemB = this.state.projects.get(b) as ProjectBean;
                        let valueItemA = this.state.projects.get(a) as ProjectBean;
                        return valueItemB.getProjectDate.getTime() - valueItemA.getProjectDate.getTime();
                      })
                      .map( (item, index) => {
                        let valueItem = this.state.projects.get(item) as ProjectBean;
                        let angle = angleSpread * index;
                        let coordinateX = 0;
                        let coordinateY = 0;
                        if (index !== 0) {
                          coordinateX = Math.cos(angle * Math.PI / 180) * radius;
                          coordinateY = Math.sin(angle * Math.PI / 180) * radius
                          console.log("angle {" + angle + "} coordinateX {" +  coordinateX + "} coordinateY {" + -coordinateY + "}")
                        }
                        return (
                          <div key={index} className="grid-unit-tile" style={{transform: `translate(${coordinateX}px, ${-coordinateY}px)`}}>
                            <Link style={{display: "flex", flexWrap: "wrap", width: "400px", height: "267px"}} to={"/projects/" + item} >
                              <div className="project-container">
                                <div className="project-header">
                                  <h5 className="grid-unit-h5">{this.converter.toWords(this.state.projects.size - index)} - {valueItem.getProjectName}</h5>
                                  <h5 className="grid-unit-h5">{valueItem.getProjectDate.getFullYear()}, {this.formatterMonths.format(valueItem.getProjectDate)}</h5>
                                </div>
                                <img src={valueItem.getProjectImg.getImageSrc} alt={valueItem.getProjectImg.getImageAlt} />
                              </div>
                              <div className="project-code">
                                <p style={{fontSize: "8px" }}> &lt;div className= "project-header"&gt; <br /> &lt;div&gt; </p>
                              </div>
                              <div className="project-footer">
                                <p style={{fontSize: "8px" }}>
                                  &lt;h5&gt; {valueItem.getProjectName} &lt;/h5&gt;
                                  &lt;h5&gt; {valueItem.getProjectDate.getFullYear()}-{valueItem.getProjectDate.getMonth()}-{valueItem.getProjectDate.getDate()} &lt;/h5&gt;
                                  <br />
                                  &lt;img src={valueItem.getProjectImg.getImageSrc} /&gt;
                                  <br />
                                  &lt;div className="project-footer"&gt; &lt;/div&gt; &lt;/div&gt;
                                </p>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    }
                  </div>

                </div>
              </div>

            </div>
          </div>
        )
      } else {
        return (
        <div className="projects-column">
          <div className={projectMenuClassName}>
            <ul>
            { projectsArray
              .sort( (a,b) => {
                let valueItemB = this.state.projects.get(b) as ProjectBean;
                let valueItemA = this.state.projects.get(a) as ProjectBean;
                return valueItemB.getProjectDate.getTime() - valueItemA.getProjectDate.getTime();
              })
              .map( (item, index) => {
                return (
                  <li key={index} className={(this.state.projectsActive.get(item)) ? 'activeProject' : ''}>
                    <a className={this.state.projectsActive.get(item) ? 'activeProject' : ''} href={"#" + item}>{this.converter.toWords(this.state.projects.size - index)} —</a>
                  </li>
                );
              })
            }
            </ul>
          </div>
          <div>
          { projectsArray
            .sort( (a,b) => {
              let valueItemB = this.state.projects.get(b) as ProjectBean;
              let valueItemA = this.state.projects.get(a) as ProjectBean;
              return valueItemB.getProjectDate.getTime() - valueItemA.getProjectDate.getTime();
            })
            .map( (item, index) => {
              let valueItem = this.state.projects.get(item) as ProjectBean;
              return (
                <div key={index} id={item} className="single-project">
                  <Link to={"/projects/" + item} >
                  <div className="project-container">
                    <div className="project-header">
                      <h5>{this.converter.toWords(this.state.projects.size - index)} - {valueItem.getProjectName}</h5>
                      <h5>{valueItem.getProjectDate.getFullYear()}, {this.formatterMonths.format(valueItem.getProjectDate)}</h5>
                    </div>
                    <img src={valueItem.getProjectImg.getImageSrc} alt={valueItem.getProjectImg.getImageAlt} />
                  </div>
                  <div className="project-code">
                    <p> 
                      &lt;div className= "project-header"&gt;
                      <br />
                      &lt;div&gt;
                    </p>
                  </div>
                  <div className="project-footer">
                    <p>
                      &lt;h5&gt; {valueItem.getProjectName} &lt;/h5&gt;
                      &lt;h5&gt; {valueItem.getProjectDate.getFullYear()}-{valueItem.getProjectDate.getMonth()}-{valueItem.getProjectDate.getDate()} &lt;/h5&gt;
                      <br />
                      &lt;img src={valueItem.getProjectImg.getImageSrc} /&gt;
                      <br />
                      &lt;div className="project-footer"&gt; &lt;/div&gt; &lt;/div&gt;
                    </p>
                  </div>
                </Link>
                </div>
              )
            })
          }
          </div>
        </div>
        )
      }
    }
}

export {ProjectsComponent};