export class Location {
    private readonly lat: string;
    private readonly lng: string;

    constructor(lat : string, lng : string) {
        this.lat = lat;
        this.lng = lng;
    }

    get getLat() : string {
        return this.lat;
    }

    get getLng() : string {
        return this.lng;
    }

    get getLocation() {
        return {lat: this.lat, lng: this.lng};
    }
}

export class LocationBean {
    private readonly name: string;
    private readonly location: Location;

    constructor(name : string, location : Location) {
        this.name = name;
        this.location = location;
    }

    get getLocation() : Location|undefined {
        return this.location;
    }
}