import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {ProjectBean} from './beans/projectBean';
import {Homepage} from './components/homepage/homepage';
import {Digital} from './components/digital/digital';
import {Projects} from './components/projects/projects';
import {Project} from './components/project/project';
import {Philosophy} from './components/philosophy/philosophy';
import {Chapter} from './components/chapter/chapter';
import {About} from './components/about/about';
import {NotFound} from './components/notFound/notFound';
import {WorkInProgress} from './components/workinprogess/workinprogress';
import {InitialLoadingPage} from './common/visual/initialLoadingPage';
import {MouseComponent} from './common/visual/mouseComponent';
import {ChapterServiceManager} from './services/chapterServiceManager';
import {ProjectServiceManager} from './services/projectServiceManager';
import {homePath} from './utils/homePath';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'video-react/dist/video-react.css';
import './index.css';



class App extends React.Component {
  
  projectsMap : Map<string, ProjectBean>;
  projectService : ProjectServiceManager;
  chapterService : ChapterServiceManager;
  DivAppRef : React.RefObject<HTMLDivElement>;
  
  constructor(props) {
    super(props);

    this.projectsMap = new Map<string, ProjectBean>();
    this.projectService = new ProjectServiceManager();
    this.chapterService = new ChapterServiceManager();
    this.DivAppRef = React.createRef();
  }
  
  state = {
    loading : true, 
    isMounted : true
  }

  handleLoadingStatus = (loadingParam : boolean) => { this.setState({loading: loadingParam}) };

  render() {
    if (this.state.loading) return (<InitialLoadingPage timeDurationTypo={8} timeDurationTimeout={3200} onLoading={this.handleLoadingStatus} />);
    else return (
      <div id="div-app" ref={this.DivAppRef}>
        <MouseComponent DivAppRef={this.DivAppRef} />
        <Router basename={homePath}>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/projects/:projectId" component={(props) => <Project key={props.match.params.projectId} {...props} projectService={this.projectService} />} />
            <Route path="/projects" component={() => <Projects projectService={this.projectService} />} />
            <Route path="/digital" component={Digital} />
            <Route path="/philosophy/:chapterId" component={(props) => <Chapter key={props.match.params.chapterId} {...props} chapterService={this.chapterService} /> } />
            <Route path="/philosophy" component={() => <Philosophy chapterService={this.chapterService} /> } />
            <Route path="/about" component={About} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/experience" component={WorkInProgress} />
            <Route path="/entertainment" component={WorkInProgress} />
            <Route path="/inspiration" component={WorkInProgress} />
            <Route path="/contributors" component={WorkInProgress} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(
    <App />,
    document.getElementById("wrapper")
);