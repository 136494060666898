export class ImageBean {
    private imageSrc: string;
    private imageAlt: string;
    private imageMobileSrc: string;

    constructor(imageSrc : string, imageAlt : string) {
        this.imageSrc = imageSrc;
        this.imageAlt = imageAlt;
        this.imageMobileSrc = imageSrc;
    }

    get getImageSrc() : string {
        return this.imageSrc;
    }

    get getImageAlt() : string {
        return this.imageAlt;
    }

    get getImageMobileSrc() : string {
        return this.imageMobileSrc;
    }

    setImageMobileSrc(imageMobileSrc : string) : void {
        this.imageMobileSrc = imageMobileSrc;
    }




}