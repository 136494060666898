import * as React from 'react';
import { Row, Col } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner, Accordion, Card, Button } from 'react-bootstrap';
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import Notifications, {notify} from 'react-notify-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProjectServiceManager } from '../../services/projectServiceManager';
import { isMobileOnly } from 'react-device-detect';
import { ProjectDetailsBean } from '../../beans/projectDetailsBean';
import { ArrowChangeComponent } from '../../common/visual/arrowChangeComponent';
import { ParagraphBean } from '../../beans/paragraphBean';
import { ImageBean } from '../../beans/imageBean';
import { VideoFormat } from '../../beans/videoFormat';
import musicIcon from '../../images/music.png';
import { homePath } from '../../utils/homePath';

library.add(fab, fas);

interface ProjectComponentProps {
    projectService : IProjectServiceManager;
    projectId: string;
    location: string;
}

interface ProjectComponentState {
    projectId : string;
    project : ProjectDetailsBean | undefined | null;
    location : string;
    items : ImageBean[];
    hasMore : boolean;
    isDown : boolean;
    sliderScrollLeft : number;
    startX : number;
    accordionActive : boolean;
    copiedLink : boolean;
}

class ProjectComponent extends React.Component<ProjectComponentProps, ProjectComponentState> {

    sliderRef = React.createRef<HTMLUListElement>();

    constructor(props) {
      super(props);
      this.state = {
        projectId: "",
        project: null,
        location: "",
        items: Array.from({ length: 0 }),
        hasMore: true,
        isDown: false,
        sliderScrollLeft: 0,
        startX: 0,
        accordionActive: false,
        copiedLink: false
      };
    }

    static getDerivedStateFromProps(nextProps, nextState){
      nextState.projectId = nextProps.projectId;
      let prevProject = nextState.project;
      nextState.project = nextProps.projectService.loadSingleProject(nextProps.projectId);
      // console.log("prevProject: " + prevProject + " - nextProject: " + nextState.project);
      if (prevProject === undefined && nextState.project === undefined) {
        window.location.replace(homePath + "/not-found");
      }
      nextState.location = nextProps.location;
      return nextState;  
    }
  
    fetchMoreData = () => {
      if (this.state.items.length >= (this.state.project?.getscrollToViewImages?.length as number)) {
        this.setState({ hasMore: false });
        return;
      }
      // a fake async api call like which sends
      // 20 more records in .5 secs
      setTimeout(() => {
        let sliced = this.state.project?.getscrollToViewImages?.slice(this.state.items.length,this.state.items.length + 2) as ImageBean[];
        this.setState({
          items: this.state.items.concat(Array.from(sliced))
        });
      }, 2000);
    }
  
    handleMouseDown = (e) => {
      this.setState({isDown: true});
      let offsetLeft = this.sliderRef.current?.offsetLeft as number;
      this.setState({startX : e.pageX - offsetLeft});
      this.setState({sliderScrollLeft : this.sliderRef.current?.scrollLeft as number});
    }
  
    handleMouseMove = (e) => {
      if  (this.state.isDown){
        e.preventDefault();
        let offsetLeft = this.sliderRef.current?.offsetLeft as number;
        let x = e.pageX - offsetLeft;
        let walk = (x - this.state.startX) * 2; //scroll-fast
        (this.sliderRef.current as HTMLUListElement).scrollLeft = this.state.sliderScrollLeft - walk;
      }
    }
    
    handleMouseLeave = (e) => {
      this.setState({isDown: false});
    }
    
    handleMouseUp = (e) => {
      this.setState({isDown: false});
    }

    handleAccordionOnClick = (e) => {
      let readMoreIcon = document.getElementsByClassName('readmore-icon')[0];
      if (this.state.accordionActive) {
        this.setState({accordionActive : false});
        readMoreIcon.classList.add("not-active");
        readMoreIcon.classList.remove("active");
      } else  {
        this.setState({accordionActive : true});
        readMoreIcon.classList.add("active");
        readMoreIcon.classList.remove("not-active");
      }
    }
  
    render() {

      let linkPreviousProject = this.props.projectService.getLinkPreviousProject(this.state.project?.getProjectId as string);
      let linkNextProject = this.props.projectService.getLinkNextProject(this.state.project?.getProjectId as string);

      let tempParagraphs : ParagraphBean[] = [];

      return (
        <div id="scrollableDiv" className="project-component">
          <Row className="project-first-row">
            <Col xs={5} className="project-code-col">
              <p>&gt;&nbsp;{this.state.location.replace(/\//, '')}</p>
            </Col>
            <Col xs={7} className="project-arrowchange-col">
              <ArrowChangeComponent 
                linkToPrevious={(linkNextProject !== undefined && linkNextProject !== "") ? "/projects/" + linkNextProject : ""} 
                linkToNext={(linkPreviousProject !== undefined && linkPreviousProject !== "") ? "/projects/" + linkPreviousProject : ""} 
                actualIndex={this.props.projectService.getProjectsSize() - this.props.projectService.getProjectIndex(this.state.project?.getProjectId as string)} 
                totalElement={this.props.projectService.getProjectsSize()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="project-description-col">
              <Row>
                <h5>{this.state.project?.getTitle as string}</h5>
              </Row>
              {this.state.project?.getParagraphs?.map( (item, index) => {
                
                if (index===0){
                  return (
                    <Row key={index}>
                      <Col xs={12} md={4} className="project-description-title">
                        <p>{item.getTitle}</p>
                      </Col>
                      <Col xs={12} md={8} className="project-description-text">
                        <p>{item.getText}</p>
                      </Col>
                    </Row>
                  )
                } else if (index===(this.state.project?.getParagraphs?.length as number) -1 ) {
                  tempParagraphs.push(new ParagraphBean(item.getTitle, item.getText));
                  return (
                    <Accordion key={index} defaultActiveKey="0">
                      <Card.Header style={{margin: 0, padding: 0}}>
                        <Accordion.Toggle style={{textDecoration: "none", color: "#ffffff", fontSize:"13px", padding: 0, margin: 0, width: "100%"}} onClick={this.handleAccordionOnClick} as={Button} variant="link" eventKey="1">
                          <Row>
                            <Col className="hidden" xs={0} md={4}>
                              &nbsp;
                            </Col>
                            <Col xs={12} md={8}>
                              <p style={{textAlign:"left", fontSize: "15px"}}>
                                <a href="#readmore">
                                [read more]
                                <FontAwesomeIcon className="readmore-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'chevron-right' })} />
                                </a>
                              </p>
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body style={{padding: 0}}>
                          {tempParagraphs.map( (value, idx) => {
                            return (
                              <Row key={idx}>
                                <Col xs={12} md={4} className="project-description-title">
                                  <p>{value.getTitle}</p>
                                </Col>
                                <Col xs={12} md={8} className="project-description-text">
                                  <p>{value.getText}</p>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Accordion>
                  )
                } else {
                  tempParagraphs.push(new ParagraphBean(item.getTitle, item.getText));
                  return null;
                }
              })}
              <Row className="bullet-text-row">
                <ul>
                {this.state.project?.getBulletText?.map( (item, index) => {
                  return (<li key={index}>{item}</li>);
                })}
                </ul>
              </Row>
            </Col>
            <Col xs={12} md={7} className="project-image-col">
              <div className="project-image">
                <h5>{this.state.project?.getLocation?.getLocation?.getLat}&nbsp;&nbsp;{this.state.project?.getLocation?.getLocation?.getLng}</h5>
                <img src={this.state.project?.getProfileImage?.getImageSrc} alt={this.state.project?.getProfileImage?.getImageAlt} />
              </div>
            </Col>
          </Row>
          <Row className="project-credits">
            <div className="icon"><a href="#music" style={{display: "block"}}><img src={musicIcon} alt="music icon"/></a></div>
            <div className="horizontal-line"></div>
            <div className="special-thanks hidden">
              <p>{this.state.project?.getCredits}</p>
            </div>
            <CopyToClipboard text={window.location.href.split('#')[0]}
              onCopy={() => {let customColor = { background: '#0E1717', text: "#FFFFFF"}; notify.show("LINK COPIED", "custom", 4000, customColor); }}>
              <div className="icon"><a href="#link" style={{display: "block"}}><FontAwesomeIcon icon={findIconDefinition({ prefix: 'fas', iconName: 'share' })} /></a></div>
            </CopyToClipboard>
            <Notifications />
            {(this.state.project?.getLinkToRelease) ? <div className="icon"><a href={this.state.project?.getLinkToRelease} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'link' })} /></a></div> : null}
          </Row>
          <Row className="scrollview-row">
            <div className="scrollview">
              <ul className={this.state.isDown ? "hs full active" : "hs full"} style={ (isMobileOnly) ? { gridTemplateColumns: '19px repeat(6, calc(80% - 40px)) 10px' } : { gridTemplateColumns: '19px repeat(6, calc(40% - 40px)) 10px' }}
                ref={this.sliderRef}
                onMouseDown={e => this.handleMouseDown(e)}
                onMouseUp={e => this.handleMouseUp(e)}
                onMouseLeave={e => this.handleMouseLeave(e)}
                onMouseMove={e => this.handleMouseMove(e)}
              
              >
              {this.state.project?.getHorizontalScrollImages?.map( (item, index) => {
                return (
                  <li key={index} className="item">
                    <img className="desktop_image" src={item.getImageSrc} alt={item.getImageAlt}/>
                    <img className="mobile_image" src={item.getImageMobileSrc} alt={item.getImageAlt}/>
                  </li>
                  );
              })}
              </ul>
            </div>
          </Row>
          <Row className="project-secondary-image-row">
            <Col xs={12} sm={6}>
              <img src={this.state.project?.getFirstVerticalImage?.getImageSrc} alt={this.state.project?.getFirstVerticalImage?.getImageAlt} />
            </Col>
            <Col xs={12} sm={6}>
              <img src={this.state.project?.getSecondVerticalImage?.getImageSrc} alt={this.state.project?.getSecondVerticalImage?.getImageSrc} />
            </Col>
          </Row>
          <Row className="project-horizontal-image-row">
            <Col xs={12}>
              <h5>{(this.state.project?.getParagraphs !== undefined) ? this.state.project?.getParagraphs[0].getText : ""}</h5>
              <img src={this.state.project?.getHorizontalImage?.getImageSrc} alt={this.state.project?.getHorizontalImage?.getImageAlt} />
            </Col>
          </Row>
          {(this.state.project?.getVideo !== undefined) ? (
            <Row className="project-video-row">
              <Col xs={12}>
                <div style={{overflow: "hidden"}}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe title={"video-" + this.state.project?.getTitle}  className={(this.state.project.getVideo.getVideoFormat === VideoFormat.FourByTre) ? 'project-embed-video' : ''} src={this.state.project?.getVideo.getVideoSrc} frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
                    <span style={{position: "absolute"}} className={(this.state.project.getVideo.getVideoFormat === VideoFormat.FourByTre) ? "video-span-4" : "video-span-16"}><a href={this.state.project?.getVideo.getVideoLink} target="_blank" rel="noopener noreferrer">{this.state.project?.getTitle}</a></span>
                  </div>
                </div>
              </Col>
            </Row>) : ""
          }
          <Row className="project-secondary-image-row">
            <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={<Spinner className="images-loading-spinner" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
              endMessage={
                <div className="end-message"></div>
              }
            >
              <div className="load-more">
                <h5>SCROLL TO LOAD MORE</h5>
                <div className="vertical-line"></div>
              </div>
              <Row>
              {this.state.items.map((item, index) => {
                  return (
                  <Col key={index} style={{marginBottom: "30px"}} xs={12} sm={6}>
                    <img src={item.getImageSrc} alt={item.getImageAlt} />
                  </Col>
                  )
              })}
              </Row>
            </InfiniteScroll>
          </Row>
          <Row className="project-final-credits-row">
            <Col className="col-left" xs={12} sm={6} style={{textAlign: "left"}}>
              <p className="left">{this.state.project?.getFashion}</p>
            </Col>
            <Col className="col-right" xs={12} sm={6} style={{textAlign: "right"}}>
              <p className="right">{this.state.project?.getProduction}</p>
            </Col>
          </Row>
        </div>
      )
    }
}

export {ProjectComponent};