import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import { ProjectServiceManager } from '../../services/projectServiceManager';
import { ProjectBean } from '../../beans/projectBean';
import { ProjectsComponent } from './projectsComponent';
import { ProjectsViewFormat } from '../../beans/projectsViewFormat';

interface ProjectsProps {
    projectService : ProjectServiceManager;
}

interface ProjectsState {
    projects : Map<string, ProjectBean>;
    projectsView : ProjectsViewFormat;
}

class Projects extends React.Component<ProjectsProps, ProjectsState> {

    constructor(props) {
        super(props);
        this.state = {
            projects : new Map<string, ProjectBean>(),
            projectsView : ProjectsViewFormat.List
        }
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this.setState({projects: this.props.projectService.loadProjects()});
    }

    handleViewChange = (projectsViewParam : ProjectsViewFormat) => { this.setState({projectsView: projectsViewParam}) };

    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={2} sm={2} className="col-social-container">
                            <SocialIconsComponent onViewChange={this.handleViewChange} actualPage={"projects"} />
                        </Col>
                        <Col xs={10} sm={8} className="col-projects-component">
                            <ProjectsComponent projectService={this.props.projectService} projectsView={this.state.projectsView} />
                        </Col>
                        <Col xs={0} sm={2}>
                            <MenuComponent isMobile={false} />
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Projects};