import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/logo.png';
import cv from '../../documents/vangibenedetta_resume.pdf';
import portfolio from '../../documents/typeyourtitle_human.pdf';

library.add(fab, fas);

class FooterComponent extends React.Component {
    render() {
      return (
        <div>
          <footer className="footer-bs">
            <Container>
              <Row>
                <Col xs={12} className="footer-website">
                  <h4>vangibenedetta.com</h4>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={4} className="footer-brand">
                  <h3>VNG
                  <img alt="VNG-B_Logo" src={logo} width="19" height="19" className="d-inline-block align-top"/>
                  B</h3> 
                  <p>
                    An archive of sensory experiences and fashion.
                    <br />
                    Obsessed with turning sensations into storytelling.
                  </p>
                </Col>
                <Col sm={12} md={2} className="footer-nav">
                  <h4>SITEMAP —</h4>
                    <div>
                      <ul className="pages">
                        <li><NavLink exact to="/projects">Prj+</NavLink></li>
                        <li><NavLink exact to="/digital">Digital</NavLink></li>
                        <li><NavLink exact to="/philosophy">Philosophy</NavLink></li>
                        <li><NavLink exact to="/about">About</NavLink></li>
                        <li><NavLink exact to="/experience">Experience</NavLink></li>
                      </ul>
                    </div>
                </Col>
                <Col sm={12} md={2} className="footer-nav">
                  <h4>CONTENTS —</h4>
                    <div>
                      <ul className="pages">
                        <li><NavLink exact to="/entertainment">Entertainment</NavLink>
                        </li>
                        <li><NavLink exact to="/inspiration">Inspiration</NavLink>
                        </li>
                        <li><NavLink exact to="/contributors">Contributors</NavLink>
                        </li>
                      </ul>
                    </div>
                </Col>
                <Col sm={12} md={2} className="footer-nav">
                    <h4>LEGAL —</h4>
                    <div>
                      <ul className="pages">
                        <li><a href="https://www.iubenda.com/privacy-policy/70169447/full-legal" target="_blank" rel="noopener noreferrer">privacy policy</a></li>
                        <li><a href="https://www.iubenda.com/terms-and-conditions/70169447" target="_blank" rel="noopener noreferrer">terms</a></li>
                        <li><a href="https://www.iubenda.com/privacy-policy/70169447/cookie-policy" target="_blank" rel="noopener noreferrer">cookie policy</a></li>
                      </ul>
                    </div>
                </Col>
                <Col sm={12} md={2} className="footer-nav">
                    <ul className="list">
                      <li><a href="mailto:info@vangibenedetta.com"><FontAwesomeIcon className="footer-social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'at' })} /></a></li>
                      <li><a href={cv} download><FontAwesomeIcon className="footer-social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'paperclip' })} /></a></li>
                      <li><a href={portfolio} download><FontAwesomeIcon className="footer-social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'paperclip' })} /></a></li>
                    </ul>
                </Col>
              </Row>
            </Container>
          </footer>
          <section className="copyright-section"><p>Vangi Benedetta | © 2020 All Rights Reserved - Designed by LV</p></section>
        </div>
      );
    }
}

export {FooterComponent};