import { ChapterBean } from "../beans/chapterBean";
import { ParagraphBean } from "../beans/paragraphBean";

export interface IChapterServiceManager {
    loadChapters: () => Map<string, ChapterBean>;
    loadSingleChapter: (chapterId : string) => ChapterBean | undefined;
    getChaptersSize: () => number;
    getChapterIndex: (chapterId : string) => number;
    getLinkPreviousChapter: (chapterId : string) => string | undefined;
    getLinkNextChapter: (chapterId : string) => string | undefined;
}


export class ChapterServiceManager implements IChapterServiceManager {
    private chaptersMap : Map<string, ChapterBean>;

    constructor() {
        this.chaptersMap = new Map<string, ChapterBean>();
    }

    public loadChapters() : Map<string, ChapterBean> {
        // load from DB or from projectsMap cache
        if (this.chaptersMap.size === 0) {
            this.addChaptersToMap();
        }
        return this.chaptersMap;
    }

    public loadSingleChapter(chapterId : string) : ChapterBean | undefined {
        if (this.chaptersMap.size === 0) {
            this.addChaptersToMap();
        }
        if (this.chaptersMap.get(chapterId) === undefined)
            return undefined;
        return this.chaptersMap.get(chapterId);
    }

    // temporary private method
    private addChaptersToMap() {
        /* -- ABSTRACT -- */
        let paragraph0_0 = new ParagraphBean("IMPACT", "This paper studies the impact of synesthesia on the contemporary luxury market, or rather how sensory perception and interpretation find a correlation with marketing. The dissertation analyses sensory composition and its influence on experiential marketing and digital communication.");
        let paragraph0_1 = new ParagraphBean("ANALYSIS", "Firstly the essay introduces the analysis of experiential marketing through the mention of the renowned researches of Bernd Schmitt — Professor of Marketing at the Columbia Business School and Faculty Director at the Center on Global Brand Leadership.");
        let paragraph0_2 = new ParagraphBean("SENSES", "Secondly, through analysis of synesthesia researches of Charles Spence — Professor of Experimental Psychology and Head of the Crossmodal Research Laboratory — it’s highlighted how «The reality is that you cannot consider vision without also considering hearing, tasting without also thinking about smelling. All the senses are internally connected.»");
        let paragraph0_3 = new ParagraphBean("CREATIVITY", "Finally, this paper aims to analyse the concept of synesthesia in relation to content creation, demonstrating how it offers interesting ideas and opportunities for innovative communication. Furthermore, it analyses the connection between synesthesia and technological innovation — such as VR and AI — underlining how this relationship can provide authoritative support to creativity.");
        let paragraph0_4 = new ParagraphBean("FUTURE", "In conclusion, the dissertation provides a perspective to the foreseeable future, emphasizing how the sensory association opens up the possibility of an innovative vision and interpretation of reality.");
        
        let paragraphs0 = [
            paragraph0_0, 
            paragraph0_1, 
            paragraph0_2, 
            paragraph0_3,
            paragraph0_4
        ]

        let id0 = "abstract";
        let title0 = "Abstract";
        this.chaptersMap.set(id0, new ChapterBean(id0, title0, paragraphs0))

        /* -- INTRODUCTION -- */
        let paragraph1_0 = new ParagraphBean("SYNESTHESIA", "Synesthesia [sin′′es-the ́zhah] a secondary sensation accompanying an actual perception; the experiencing of a sensation in one place, due to stimulation applied to another place; also, the condition in which a stimulus of one sense is perceived as sensation of a different sense, as when a sound produces a sensation of color. (Miller-Keane Encyclopedia and Dictionary of Medicine, Nursing, and Allied Health, 2003)");
        let paragraph1_1 = new ParagraphBean("SENSORIAL", "Synesthesia is a rare neuropsychological phenomenon which affects a limited number of people. Meanwhile, this inter-sensoriality occurrence could be also analysed from cultural and social points of view, which lead to anthropological synesthesia. Nowadays multisensorial associations represent a crucial means to engage audiences. In this context, sensory perception constitutes an integral part of storytelling, namely a persuasive communication and marketing strategy which companies embrace to communicate their identity and values. Therefore, anthropological synesthesia and multisensorial associations should be currently considered as worthwhile marketing tools (Howes, 2011).");
        let paragraph1_2 = new ParagraphBean("EXPERIENCES", "This paper investigates the influence of multisensorial experiences in the luxury market, aiming to underline the cruciality of experiential marketing for a brand’s successful communication strategy. Nowadays, the real challenge for companies is to replicate multi- sensory engagement in a digital environment. This dissertation analyses how anthropological synesthesia might affect the luxury market, including fashion, in the contemporary and foreseeable future.");
        
        let paragraphs1 = [
            paragraph1_0, 
            paragraph1_1, 
            paragraph1_2
        ]

        let id1 = "introduction";
        let title1 = "Introduction";
        this.chaptersMap.set(id1, new ChapterBean(id1, title1, paragraphs1))

        /* -- DISCUSSION - The experiential marketing -- */

        let paragraph2_0 = new ParagraphBean("CONCEPT", "The experiential marketing is a deeply rooted concept. In the context of fashion and luxury management, it represents the contemporary boundary in communication strategy. The main goal is to predict the consumer’s feelings and consumption activities towards the brand. This commercial approach is based on the very definition of experience. Firstly, it refers to the past and memory hence how people remember a particular time or event; and afterwards how these whole previous events constitute the cultural experience which might influence future actions. Secondly, it refers to a specific moment which happens in the present — here and now — and related to the combination of sensations and feelings that it evokes (Gove, 1976).");
        let paragraph2_1 = new ParagraphBean("BENEFITS", "On the occasion of the Association for Consumer Research Conference (2009), Chris Janiszewski states that «Benefits are not in the products. Benefits are in the consumer experience»., pointing out that mere goods are not enough to succeed in marketing sales (Cited by Schmitt, 2011).");
        let paragraph2_2 = new ParagraphBean("MARKETING", "The experiential marketing has been extensively researched by Bernd Schmitt, Professor of Marketing at the Columbia Business School and Faculty Director at the Center on Global Brand Leadership, who represents one of the most prominent contributors to this topic. His work focuses on customer experience and behaviour related to innovative marketing and communication strategy. According to Bernd Schmitt, sensory perception represents one of the crucial experience of marketing approaches (Schmitt, 2011).");
        let paragraph2_3 = new ParagraphBean("CUSTOMER", "In general, the experiential marketing is strictly connected with customer experience, since it boosts the creation of an interactive relationship between consumer and brand. As a consequence, the core of this marketing strategy concerns a reassessment of the consumer’s role, who goes from being an observer to being an involved in-person actor. Nowadays, people play an active role in experience strategy, which has been also defined as event marketing (Pine and Gilmore, 1999). In this perspective, companies should mark their communication with consistent production of events and entertainment production formats.");

        let paragraph2_4 = new ParagraphBean("FOMO", "Over the past decade, experiences have a greater economic value than products, especially among the younger generations. According to a report on behalf of Eventbrite (2014), US millennials fuel economy by interesting in experiencing life’s opportunities. In this regard, they prefer spending money on events, in order to fulfill their need for creativity and contents creation. Furthermore, this approach is emphasized by the cultural phenomenon of FOMO, i.e. the fear of missing out memorable and once in a lifetime events and experiences. As a consequence, fashion companies have strived to invest resources to engage this audience. This is the reason why, companies are nowadays engaged in selling stories and values, rather than products.");
        let paragraph2_5 = new ParagraphBean("EMOTIONS", "Therefore, experiential marketing and synesthesia are closely related, as multisensorial associations have a greater influence on consumers’ behaviour. On the assumption that purchasing is driven by emotions, five senses experiences are useful for steering sales. According to the Journal of Marketing Research, sounds and colors have the power to drive consumers’ visual attention and being crucial to induce an unaware purchase (Brasel and Hagtvedt, 2016).");
        let paragraph2_6 = new ParagraphBean("VISUAL", "On the subject of the relationship between marketing and senses, the visual approach is longer considered as the main sense. From here on, the hearing involvement is crucial to crowning and enhancing a multi-sensory marketing strategy. In this concern, the association and stimulation of both senses turn out to be ineluctable. In experiential marketing, hearing is considered as a sub-sense for multi-level cross-associations, as sounds have an enhanced potential.");
        let paragraph2_7 = new ParagraphBean("HEARING", "This can be seen in the business model of the industry leader Soundtrack Your Brand, formerly known as Spotify Business. The company operates by building up background sounds for companies. According to a research of Soundtrack Your Brand in partnership with Stockholm University, business-related sounds represents a powerful tool. In this concern, «Background music should be considered as an integral part of the brand experience». In other words, the sounds’ choice should reflect the brands’ values and be consistent with the results to be achieved from a specific audience. Besides the correlation of hearing with corporate and marketing communication is evidenced by auditory memory. Scientific researches have pointed out a direct interrelation between music and human memory. As a consequence sound is a means to boost associations (Milliman, 1986). As previously stated, businesses may implement a hearing communication strategy in order to convey brand’s message and impact consumers’ behaviour.");

        let paragraph2_8 = new ParagraphBean("TOUCH", "Meanwhile, touch seems to have a great influence on buying experiences. According to Joann Peck, Professor of Marketing at the University of Wisconsin-Madison School of Business, «Touch can increase purchase intent because it gives us more confidence in our choice to buy». (Cited by Magnarelli, Forbes, 2018). In particular, the haptic perception represents a worth index as it has a dual influence on consumer behaviour. On the one hand, it turns out to be an instrumental factor, thus a tool to assess the technical and physical characteristics of a product. In most cases, the latter is the prevailing aspect of the analysis. On the other hand, the touch awareness is related to an emotional factor, namely the one linked to the sensations conveyed by this experience; however, it is conditioned and subordinated to the previous aspect. Nonetheless, the emotional factor is crucial when the instrumental factor is able to analyse few data. In this case, spontaneous emotions can trigger an impulsive purchase.");
        let paragraph2_9 = new ParagraphBean("STORE", "Under such circumstances, touch represents a significant hurdles on the subject of digital multi-sensory experiences’ marketing. An instance of this, the global corporation Amazon opens a fashion pop-up store in London (2018) to introduce people to touch experience. The idea is to acquiesce consumers’ need for feeling the product, in order to increase the company’s brand awareness.");
        let paragraph2_10 = new ParagraphBean("OBSTACLE", "Meanwhile, taste and smell might constitute a great obstacle to overcome by companies in order to create a comprehensive marketing experience, with the exception of companies operating in these fields.");
        let paragraph2_11 = new ParagraphBean("TASTE", "On the one hand, several luxury companies are not able to guarantee a taste-related shopping experience, as food is not part of the range of branded products. In this context, most of the companies operate through temporary collaborations, focusing on single-event vision. Meanwhile, some luxury companies decide to release a brand extension, i.e. a marketing strategy that involves the launch and distribution of a product that is distant from the brand’s main and well-known merchandise production. The latter is the instance of Armani/Dolci, which represents the expression of the luxury renowned brand in the world of taste. What is today a lifestyle line of Giorgio Armani S.p.A., has been founded in 2002 to enrich the multisensory shopping experience inside the flagship store Armani/Manzoni 31 in Milan. Nowadays, Armani/Dolci represents the Italian flavour all over the world. After a long a successful production under the brand Venchi S.p.A., from 2019 Armani/Dolci is produced by Guido Goino S.r.l., namely an Italian artisan company with an international reputation. While enjoying a complete luxury experience, Armani loyal client increases his own brand value perception. In this regard, companies aim to boost their image in several markets through products which reflect brands’ values, sumptuousness, and visual identity.");
        
        let paragraph2_12 = new ParagraphBean("SMELL", "On the other hand, the smell seems to be the most memory-related sense, due to human’s anatomy. In this regard, a pleasant olfactory experience can be remembered for a long time. According to an article published on Harvard Business Review, it’s necessary for enterprises to «think about your [their] brand in a new way by considering how scent can play a role in making a more powerful impression on your [their] customers.» (Walsh, 2020) That’s the reason why several luxury companies choose to rely on master perfumers’ noses to recreate their own signature scent.");
        let paragraph2_13 = new ParagraphBean("MEMORY", "According to Dawn Goldworm, 12.29’s Co-Founder and Nose, internationally recognized olfactive expert highlights how smell could help create a relationship between consumer and brand, considering that «smell and emotion are stored as one memory». On this subject, 12.29 has been described as an olfactive branding company providing luxury enterprises with recognizable fragrances which communicate the brand’s identity and values.");
        let paragraph2_14 = new ParagraphBean("BRAND", "«We translate the brand into a smell that should and does communicate the voice of the brand.» Specifically, this creative process is aimed to build up an olfactory and sensory path that triggers emotion, which includes socio-cultural researches. In this regard, 12.29 analyses customers’ behaviour, preferences, and habits in order to create customized experiences that fit both brand and audience.");
        let paragraph2_15 = new ParagraphBean("SCENT", "This applies to Valentino under the creative direction of Maria Grazia Chiuri e Pier Paolo Piccioli (2014). They commissioned a fragrance which should have represented the essence of the brand’s luxury fashion. Through this scent, the Co-Creative Directors enhanced their works, thus admirable and enjoyable during in-boutique shopping experiences, showrooms, and fashion shows.");

        let paragraph2_16 = new ParagraphBean("SPENCE", "On the subject of synesthesia and experiential marketing, Charles Spence, Professor of Experimental Psychology and Head of the Crossmodal Research Laboratory, dedicates his studies to sensory perception and its elaboration on behalf of human’s brain. According to his researches, «The reality is that you cannot consider vision without also considering hearing, tasting without also thinking about smelling. All the senses are internaly connected» (Spence, 2016).");
        let paragraph2_17 = new ParagraphBean("SENSORIAL", "In traditional psychological and neuroscientific works, the human senses are analyzed singularly. On the contrary, Spence’s led to a contemporary and novel vision of cross-modal perception. According to his studies, multi-sensorial experiences might increase the experiential usability and enjoyment of everyday actions. Thus cross-sensory associations might boost taste, for example, offering a new way of living and perceiving food and drinks.");
        let paragraph2_18 = new ParagraphBean("TEDX", "On the occasion of his talk «Unexpected connections» for TEDxUHasselt, Charles Spence (2014) describes how these perceptual links could be used to influence pleasure and pain by enhancing or reducing them. Likewise, enterprises should work on these unexpected connections to boost delight in their consumers both from shopping experiences and products. In this regard, businesses should work on clients’ expectations, as they seem to change how human’s being experience life events. In other words, the confirmation of an individual’s initial expectation towards products or experiences strongly affects his opinion about them. On the contrary, the disconfirmation of these expectations will probably ruin the customer’s experience. In general, the assumption might be affected both by sensory perception based information, such as colors and sounds, and cultural preconceptions. In this context, the price plays an active role on the hype’s influence. As a matter of fact, the economic value of a product has an impact on consumers’ judgment. On equal quality terms, a more expensive product is considered superior, as the price affects the brand value perception. That’s the reason why several luxury brands includes more affordable products to their merchandise offer. Typically, thess kinds of items are priced higher than the average of market supply, but lower than the brand’s standards. Through these products, brands address to a wider audience, who aims to feel the pleasure of purchasing both luxury goods and shopping experiences. Finally, the unexpected connections are strictly related to illusions and contributes generating a sort of novel, simulated reality.");
        let paragraph2_19 = new ParagraphBean("IMMERSIVE", "In this regard, in the occasion of his annual IK Prize (2015), Tate Britain has promoted «Tate Sensorium», an immersive art exhibition which involves the five senses. The use of technologies has led to a novel fruition of artworks. It is possible to state that the multi- sensorial association has provide a renewed experience, even if it concerns well-know released works of art. All of the above underlines that it is essential for companies to build up an experiential marketing memorable and shareable, since synesthesia should feature prominently in storytelling, as the core of contemporary and foreseeable future of marketing strategies. In this context, multi-sensorial marketing can apply in a wide range of fields, in order to extend sensory involvement throughout the entire purchasing process.");

        let paragraph2_20 = new ParagraphBean("CAMPAIGN", "On the one hand, it might concern a pre-purchase phase, such as advertising campaigns. Just to think of Prada Seditious Simplicity, the immersive Resort 2020 campaign realized by the namesake company. On this occasion, the well-know brand chose to wrap flowers’ bouquet with printed paper with images from the new collection. In this regard, Prada has captured the consumer’s attention by veiling the advertising character of this marketing action. In general, this kind of guerrilla marketing makes the consumer feel less targeted by advertising messages. On the contrary, the consumer feels like an integral part of the brand. Synesthetic-like connections both help companies to boost their sales and to strengthen brands’ awareness. As a consequence, memorable experiences fulfill enterprises’ need to raise clients’ retention.");
        let paragraph2_21 = new ParagraphBean("ATTENTION", "According to Evyenia Lyons, Vice President of Marketing at Traackr, i.e. renowned influencer marketing agency, «Most people hate advertisements. Guerrilla marketing, when done well, intercepts an audience in a unique way and captures attention» (Cited by Chitrakorn, Vogue Business, 2020).");
        let paragraph2_22 = new ParagraphBean("BOX", "On the other hand, the post-purchase phase is essential to ensure and enhance brand value perception. In this regard, packaging represents the key to companies’ visual identity. One of the most outstanding examples is represented by Apple, whose minimalistic white box has become iconic. In addition to having a pleasant aesthetics, that box is designed to improve the unpacking experience of the purchased product. The precise moment the client first opens the box becomes a sort of ceremonial ritual; the lid slowly slides: it takes two or three seconds to reveal your new Apple-branded product.");
        let paragraph2_23 = new ParagraphBean("EVENTS", "Nevertheless, the most important moment is still constituted by live experiences, such as in- store visits and events. As mentioned, the latter represents the key to experiential marketing. In March 2020, Gucci Beauty has launched the new product Mascara L’Obscur through a pop-up store’s immersive experience.");

        let paragraphs2 = [
            paragraph2_0, 
            paragraph2_1, 
            paragraph2_2,
            paragraph2_3, 
            paragraph2_4, 
            paragraph2_5,
            paragraph2_6, 
            paragraph2_7, 
            paragraph2_8,
            paragraph2_9, 
            paragraph2_10, 
            paragraph2_11,
            paragraph2_12, 
            paragraph2_13, 
            paragraph2_14,
            paragraph2_15, 
            paragraph2_16, 
            paragraph2_17,
            paragraph2_18, 
            paragraph2_19, 
            paragraph2_20,
            paragraph2_21, 
            paragraph2_22, 
            paragraph2_23
        ]
        let id2 = "experiential-marketing";
        let title2 = "Discussion - The experiential marketing";
        this.chaptersMap.set(id2, new ChapterBean(id2, title2, paragraphs2))

        /* -- DISCUSSION - The digital experiential marketing -- */
        let paragraph3_0 = new ParagraphBean("TECHNOLOGY", "The contemporary and foreseeable future of experiential marketing is however represented by the use of technology, with the aim of developing and implementing it in digital experiences. Nowadays it is inconceivable for enterprises to design a marketing strategy without considering the online environment.");
        let paragraph3_1 = new ParagraphBean("DIGITAL", "The implementation of digital switchover can be done at two major levels. Firstly, through digital marketing, including social media management and official online channels. Secondly, through advanced technologies, such as virtual reality and artificial intelligence.");
        let paragraph3_2 = new ParagraphBean("DATA", "From the point of view of digital marketing, experiential marketing represents an imperative shift to reach a wider audience, without increasing costs exponentially. However, the main advantages concern data, which are considered as the oil of the new century.");
        let paragraph3_3 = new ParagraphBean("REPORTS", "The online experience offers countless potential in terms of statistics and traceability, allowing access to real-time statistics. This makes it possible to measure the success of the experience, without the need for outspoken feedbacks from customers. Whereas offline events’ performances are evaluated by customers’ attendance and surveys, in online environments they’re measured through unequivocal parameters, such as visits, interactions, sharing, dwell time, etc. Furthermore, online experiences open up to the possibility of constant evolution, in order to fulfill consumers’ expectations. In this regard, analyzing and predicting consumers’ needs is crucial to influence their purchasing experiences. Nowadays companies based their strategies on market intelligence reports. In this context, they create several versions of the same online experience and evaluate the best one by analysing a sample of users’ responses through A/B tests.");
        
        let paragraph3_4 = new ParagraphBean("CONNECTIONS", "According to Dmitrii Kustov, Internet Marketing Director at Regex SEO, «Brands now have the opportunity to find real connections with their audience» (Cited by Forbes Agency Council, Forbes, 2020).");
        let paragraph3_5 = new ParagraphBean("EMOTIONS", "Generally, synesthesia and digital marketing are intrinsically related, as they share two fundamental aspects of experiential marketing such as engagement and brand awareness: the abilities to evoke emotions and creating a community. As a consequence, communities might be engaged both indirectly and directly.");
        let paragraph3_6 = new ParagraphBean("SOCIAL", "The former occurs in the field of social media management when companies address to a restricted group of people, which act as intermediaries. In this regard, most brands dedicate exclusive events to the press and influencers, who become ambassadors. The main goal is to communicate the company’s storytelling using influencers’ tone of voice. In this concern, the individual’s senses and multisensory perceptions are placed at the service of a large number of people. The main advantage is represented by the fact that the brand’s identity and values become reachable to a wide range of people. On the contrary, this type of strategy implies less control on behalf of enterprises, which are limited to a super partes supervisory control.");
        let paragraph3_7 = new ParagraphBean("INFLUENCERS", "Finally, «influencers who provide enjoyable, immersive experiences boost brand visibility, build audience connections and drive action», as claimed by Danielle Wiley, Founder & CEO of Sway Group (Cited by Forbes Agency Council, Forbes, 2020).");
        
        let paragraph3_8 = new ParagraphBean("INTERACTIVE", "The latter, hence direct engagement, aims to increase network traffic on companies’ official channels, such as websites. In this concern, brands strive multi-sensorial experiences by enhancing sight and hearing, in order to overcome the lack of other senses. The main idea is to create interactive storytelling to capture consumers’.");
        let paragraph3_9 = new ParagraphBean("WEBSITE", "One of the best-realized examples of multisensory experiences’ websites is represented by Comme des Garçons. The brand launches a sub-domain to celebrate his fragrances line Self Scenter. This digital environment offers customers’ a journey constitutes by images, sounds, graphics and gesture. In this regard, users’ are called upon to make choices, in order to enjoy a customized entertainment experience. The site navigation, consistent with Comme des Garçons aesthetics, therefore represents almost a revelation of both products and the brand.");
        let paragraph3_10 = new ParagraphBean("SOLUTIONS", "Nonetheless, the real challenge of creators is to offer innovative and versatile solutions to create virtual multisensory experiences. Lately, experiential marketing has been increasingly evolving, thanks to the great opportunity to involve senses through a screen.");
        let paragraph3_11 = new ParagraphBean("HAPTIC", "In this context, the company Haptx has joined the world market with a novel ground- breaking haptic professional technology which enables VR simulation «to bring lifelike touch to virtual reality». In 2018, HaptX Gloves, i.e. gloves that allow customers to perceive the tactile experience of virtual objects, has been launched on the market. Microfluidic technology enables the feeling of their physical traits, such as texture, weights, and movement, with the purpose of offering a realistic digital experience. In other words, the software reproduces convincing tactile sensations, making use of the technology’s ability to recognize users’ position in space. This company has pioneered a methodology that has increased the potential of VR environments, offering an absolutely innovative approach to the virtual reproduction of human interactions and sensations.");

        let paragraph3_12 = new ParagraphBean("TACTILE", "Likewise, the Synesthesia Suit is a project born from the collaboration of artists Enhance, Rhizomatiks, and Keio Media Design which aims to extend the tactile experience to the whole body. This suit provides increasing the sensory experience of sight and hearing through a tactile experience, which replicate the real on thanks to several actuators. In other words, the Synesthesia Suit is able to reproduce different types of sensations, taking into account the body parts and their heterogeneous sensibilities.");
        let paragraph3_13 = new ParagraphBean("ENGAGE", "Meanwhile, the relationship between synesthesia and marketing surely affects content creation, as a consequence. Brands often outsource the production of multimedia features. In this regard, creators aim to captivate and engage their audience in a digital environment.");
        let paragraph3_14 = new ParagraphBean("AI", "Xander Steenbrugge — a Machine Learning researcher — founded Neural Synesthesia, a project which aims to «explores new approaches to audiovisual experience based on Artificial Intelligence». The creator’s workflow’s based on a close co-operation between Human and Machine. In this regard, he trains the AI to produced visual outputs based on human’s previously collected data. Through a complex algorithm, the machine could generate novel and original contents grounded on preset visual concepts. Xander Steenbrugge operates on the machine’s process by introducing an audio track. In this concern, the Artificial Intelligence starts combining visual outputs to auditory outputs. Lastly, he creatively reworks on the Machine’s produced results. In other words, the Machine Learning researcher has developed a digital process simulating Synesthesia, which aims to reproduce virtual multi- sensorial associations. In this respect, the user faces with novel contents, which had already constituted by a combination of sensory stimuli. Since the digital environment cannot always subdue consumers to powerful sensory outputs, AI simulates the processing of these stimuli by the human brain.");

        let paragraphs3 = [
            paragraph3_0, 
            paragraph3_1, 
            paragraph3_2,
            paragraph3_3, 
            paragraph3_4, 
            paragraph3_5,
            paragraph3_6, 
            paragraph3_7, 
            paragraph3_8,
            paragraph3_9, 
            paragraph3_10, 
            paragraph3_11,
            paragraph3_12, 
            paragraph3_13, 
            paragraph3_14
        ]

        let id3 = "digital-experiential-marketing";
        let title3 = "Discussion - The digital experiential marketing";
        this.chaptersMap.set(id3, new ChapterBean(id3, title3, paragraphs3))

        /* -- CONCLUSION -- */

        let paragraph4_0 = new ParagraphBean("INTUITION", "In conclusion, what has been said so far is a vision of synesthesia in its infancy. In a pioneering intuition for the near future, this neurological phenomenon represents the inner core of neuromarketing.");
        let paragraph4_1 = new ParagraphBean("BENEFITS", "Nowadays, researchers’ main purpose is to lead this topic to a more wide-ranging analysis. The idea is to understand how a rare genetic condition could influence an extensive audience, with the aim to convey the benefits of neurological synesthesia to average individuals.");
        let paragraph4_2 = new ParagraphBean("MARKETING", "In this respect, it’s crucial to consider the features of this rare genetic condition, in order to replicate multi-sensorial benefits in an induced similar-phenomenon. The studies’ key issues remain to understand the relationship between neuropsychological synesthesia and creativeness, and how this bond could be exploited in non-synthetic subjects. Within the context of experimental scientific researches, it has been shown that cross-modal associations could positively influence creativity. This hypothesis is supported by a clear increased engagement of synesthetic people in creative fields. Consequently, the inter-sensoriality connections could boost the creation of even-greater contents, loaded with emotional involvement. «Synesthetes have greater usage of mental imagery, verbal comprehension, and greater originality of verbal divergent thinking.» (Chun and Hupé, 2015). Although it has not yet been verified a straightforward correlation between these factors, these arguments represent a primary starting point. In the foreseeable future, the functioning of this rare neurological condition might represent an important step to build up and develop novel experiential marketing models.");
        
        let paragraphs4 = [
            paragraph4_0, 
            paragraph4_1, 
            paragraph4_2
        ]

        let id4 = "conclusion";
        let title4 = "Conclusion";
        this.chaptersMap.set(id4, new ChapterBean(id4, title4, paragraphs4))
    }

    public getChaptersSize() : number {
        return this.chaptersMap.size;
    }

    public getChapterIndex(chapterId : string) : number {
        let chapterArray : string[] = Array.from(this.chaptersMap.keys());
        return chapterArray.findIndex( (value, index) => (value === chapterId) );
    }

    public getLinkPreviousChapter(chapterId : string) : string | undefined {
        let chapterArray : string[] = Array.from(this.chaptersMap.keys());
        let idx = chapterArray.findIndex( (value, index) => (value === chapterId) );
        return (idx === -1 || ((idx - 1) < 0)) ? undefined : chapterArray[idx - 1];
    }

    public getLinkNextChapter(chapterId : string) : string | undefined {
        let chapterArray : string[] = Array.from(this.chaptersMap.keys());
        let idx = chapterArray.findIndex( (value, index) => (value === chapterId) );
        return (idx === -1 || ((idx + 1) > (chapterArray.length-1))) ? undefined : chapterArray[idx + 1];
    }
}