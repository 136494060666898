import { VideoFormat } from "./videoFormat";

export class VideoBean {
    private videoSrc: string;
    private videoLink: string;
    private videoFormat: VideoFormat;

    constructor(videoSrc : string, videoLink : string, videoFormat : VideoFormat) {
        this.videoSrc = videoSrc;
        this.videoLink = videoLink;
        this.videoFormat = videoFormat;
    }

    get getVideoSrc() : string {
        return this.videoSrc;
    }

    get getVideoLink() : string {
        return this.videoLink;
    }

    get getVideoFormat() : VideoFormat {
        return this.videoFormat;
    }

}