import * as React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface ArrowChangeComponentProps {
    linkToPrevious?: string;
    linkToNext?: string;
    actualIndex: number;
    totalElement: number;
    style?: React.CSSProperties;
    className?: string;
    vertical?: boolean;
    horizontalIndexAndElement?: boolean;
}

interface ArrowChangeComponentState {}

class ArrowChangeComponent extends React.Component<ArrowChangeComponentProps, ArrowChangeComponentState> {
  
    render() {
        const parentStyle = {
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            fontFamily: "WorkSans-Regular",
            fontSize: "18px"
        } as React.CSSProperties;

        const verticalParentDiv = ((this.props.vertical !== undefined && this.props.vertical !== false) 
        ? {
            flexDirection: "column", 
            transform: "translateY(-50%)"
        } : {}) as React.CSSProperties;

        const verticalDiv = ((this.props.vertical !== undefined && this.props.vertical !== false) 
        ? {
            writingMode: "vertical-lr",
            padding: "10px 0px"
        } : {}) as React.CSSProperties;

        const horizontalNumbers = ((this.props.vertical === undefined || this.props.vertical === true) 
        ? {
            writingMode: "horizontal-tb"
        } : {}) as React.CSSProperties;

        return (
            <div style={{...parentStyle, ...verticalParentDiv, ...this.props.style} as React.CSSProperties} className={`arrow-change-component ${this.props.className || ''}`}>
                {(this.props.linkToPrevious !== undefined && this.props.linkToPrevious !== "") 
                    ? <div style={verticalDiv} className="linkToPrevious">
                        <Link to={this.props.linkToPrevious} className="arrowIcon"><FontAwesomeIcon icon={findIconDefinition({ prefix: 'fas', iconName: 'chevron-left' })} /></Link>
                    </div>
                    : ""
                }
                <div style={{...verticalDiv, ...horizontalNumbers} as React.CSSProperties} className="actualIndex">{this.props.actualIndex}</div>
                <div style={verticalDiv}>|</div>
                <div style={{...verticalDiv, ...horizontalNumbers} as React.CSSProperties} className="totalElement">{this.props.totalElement}</div>
                {(this.props.linkToNext !== undefined && this.props.linkToNext !== "")
                    ? <div style={verticalDiv} className="linkToNext">
                        <Link to={this.props.linkToNext} className="arrowIcon"><FontAwesomeIcon icon={findIconDefinition({ prefix: 'fas', iconName: 'chevron-right' })} /></Link>
                    </div>
                    : ""
                }
            </div>
        );
    }
}

export {ArrowChangeComponent};