import * as React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const homePath = process.env.PUBLIC_URL;

interface MenuComponentProps {
    isMobile: boolean
}

interface MenuComponentState {
    isOpened: boolean
}

class MenuComponent extends React.Component<MenuComponentProps, MenuComponentState> {
  
    state = { isOpened: false }
  
    componentDidMount() {
      if (this.isMobileSet()) {
        window.addEventListener('click', this.handleBodyClick);
      }
    }
  
    componentWillUnmount() {
      if (this.isMobileSet()) {
        window.removeEventListener('click', this.handleBodyClick);
      }
    }
  
    isMobileSet() : boolean {
      return this.props.isMobile;
    }
  
    handleMenuStatus = (menuOpenParam : boolean) => { this.setState({isOpened: menuOpenParam}) };
  
    handleBodyClick = (event) => {
      if (this.state?.isOpened) {
        this.handleMenuStatus(false);
      }
    }
  
    menuItems = [
      {
        text: "HOME",
        to: "/",
        presentOnHome: true
      },
      {
        text: "PRJ+",
        to: "/projects",
        presentOnHome: false
      },
      {
        text: "DIGITAL",
        to: "/digital",
        presentOnHome: true
      },
      {
        text: "PHILOSOPHY",
        to: "/philosophy",
        presentOnHome: true
      },
      {
        text: "ABOUT",
        to: "/about",
        presentOnHome: true
      }
    ];
  
    render(){
        if (this.isMobileSet()){
            return (
            <div className={this.state.isOpened ? "open opened" : "open"} onClick={(event) => {this.handleMenuStatus(!this.state.isOpened); event.stopPropagation()}}>
                <span className="cls"></span>
                <span>
                    <ul className="sub-menu">
                        { (this.menuItems)
                            .filter(item => window.location.pathname === homePath + item.to)
                            .map((item, index) => {
                            return <li key={index}><NavLink className="navLink-mobile" activeClassName="selected" exact to={item.to}>{item.text}</NavLink></li>;
                            })
                        }
                        { (this.menuItems)
                            .filter(item => window.location.pathname !== homePath + item.to)
                            .filter(item => { 
                            if ((window.location.pathname === (homePath + "/")) || (window.location.pathname === (homePath))){ 
                                return item.presentOnHome;
                            }
                            return true;
                            })
                            .map( (item, index) => {
                            return <li key={index}><NavLink className="navLink-mobile" activeClassName="selected" exact to={item.to}>{item.text}</NavLink></li>;
                            })
                        }
                    </ul>
                </span>
                <span className="cls" onClick={() => this.handleMenuStatus(false)}></span>
            </div>
            )
        } else {
            return (
            <Nav id="nav-menu" className="flex-column flex-nav">
                { (this.menuItems)
                    .filter(item => window.location.pathname === homePath + item.to)
                    .map((item, index) => {
                    return <NavLink key={index} className="navLink" activeClassName="selected" exact to={item.to}>{item.text}</NavLink>;
                    })
                }
                { (this.menuItems)
                    .filter(item => window.location.pathname !== homePath + item.to)
                    .filter(item => { 
                    if ((window.location.pathname === (homePath + "/")) || (window.location.pathname === (homePath))){ 
                        return item.presentOnHome;
                    }
                    return true;
                    })
                    .map( (item, index) => {
                    return <NavLink key={index} className="navLink" activeClassName="selected" exact to={item.to}>{item.text}</NavLink>;
                    })
                }
            </Nav>
            )
        }
    }
}

export {MenuComponent};