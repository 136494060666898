import { ProjectBean } from "./projectBean";
import { ProjectDetailsBuilder } from "./projectDetailsBuilder";
import { LocationBean } from "./locationBean";
import { ImageBean } from "./imageBean";
import { VideoBean } from "./videoBean";
import { ParagraphBean } from "./paragraphBean";

export class ProjectDetailsBean extends ProjectBean {

    private location: LocationBean | undefined = undefined;
    private profileImage: ImageBean | undefined = undefined;
    private title: string | undefined = undefined;
    private paragraphs: ParagraphBean[] | undefined = undefined;
    private bulletText: string[] | undefined = undefined;
    private credits: string | undefined = undefined;
    private linkToRelease: string | undefined = undefined;
    private horizontalScrollImages: ImageBean[] | undefined = undefined;
    private video: VideoBean | undefined = undefined;
    private firstVerticalImage: ImageBean | undefined = undefined;
    private secondVerticalImage: ImageBean | undefined = undefined;
    private horizontalImage: ImageBean | undefined = undefined;
    private scrollToViewImages: ImageBean[] | undefined = undefined;
    private fashion: string | undefined = undefined;
    private production: string | undefined = undefined;

    constructor(projectDetailsBuilder : ProjectDetailsBuilder) {
        super(projectDetailsBuilder.getProjectBean.getProjectId, 
            projectDetailsBuilder.getProjectBean.getProjectName, 
            projectDetailsBuilder.getProjectBean.getProjectDate, 
            projectDetailsBuilder.getProjectBean.getProjectImg);
        this.location = projectDetailsBuilder.getLocation;
        this.profileImage = projectDetailsBuilder.getProfileImage;
        this.title = projectDetailsBuilder.getTitle;
        this.paragraphs = projectDetailsBuilder.getParagraphs;
        this.bulletText = projectDetailsBuilder.getBulletText;
        this.credits = projectDetailsBuilder.getCredits;
        this.linkToRelease = projectDetailsBuilder.getLinkToRelease;
        this.horizontalScrollImages = projectDetailsBuilder.getHorizontalScrollImages;
        this.video = projectDetailsBuilder.getVideo;
        this.firstVerticalImage = projectDetailsBuilder.getFirstVerticalImage;
        this.secondVerticalImage = projectDetailsBuilder.getSecondVerticalImage;
        this.horizontalImage = projectDetailsBuilder.getHorizontalImage;
        this.scrollToViewImages = projectDetailsBuilder.getscrollToViewImages;
        this.fashion = projectDetailsBuilder.getFashion;
        this.production = projectDetailsBuilder.getProduction;
    }

    get getLocation() : LocationBean|undefined {
        return this.location;
    }

    get getProfileImage() : ImageBean|undefined {
        return this.profileImage;
    }

    get getTitle() : string|undefined {
        return this.title;
    }

    get getParagraphs() : ParagraphBean[]|undefined {
        return this.paragraphs;
    }

    get getBulletText() : string[]|undefined {
        return this.bulletText;
    }

    get getCredits() : string|undefined {
        return this.credits;
    }

    get getLinkToRelease() : string|undefined {
        return this.linkToRelease;
    }

    get getHorizontalScrollImages() : ImageBean[]|undefined {
        return this.horizontalScrollImages;
    }

    get getVideo() : VideoBean|undefined {
        return this.video;
    }

    get getFirstVerticalImage() : ImageBean|undefined {
        return this.firstVerticalImage;
    }

    get getSecondVerticalImage() : ImageBean|undefined {
        return this.secondVerticalImage;
    }

    get getHorizontalImage() : ImageBean|undefined {
        return this.horizontalImage;
    }

    get getscrollToViewImages() : ImageBean[]|undefined {
        return this.scrollToViewImages;
    }

    get getFashion() : string|undefined {
        return this.fashion;
    }

    get getProduction() : string|undefined {
        return this.production;
    }

}