import * as React from 'react';
import { Player, LoadingSpinner, BigPlayButton, Shortcut } from 'video-react';
import { isMobileOnly } from 'react-device-detect';
import videomp4 from '../../videos/sogno.mp4';
import sognolucido_mobile from '../../images/sognolucido_mobile.jpg';

class BackgroundVideoComponent extends React.Component {

    timer!: NodeJS.Timeout;
    videoRef : React.RefObject<Player>;
    videoRefMobile : React.RefObject<HTMLVideoElement>;

    constructor(props) {
      super(props);
      this.videoRef = React.createRef();
      this.videoRefMobile = React.createRef();
    } 

    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    setVideoTimeout() {
      this.timer = setTimeout(() => {
        this.videoRef.current.pause();
      }, 60000);
    }

    newShortcuts = [
      // {
      //   keyCode: 32, // spacebar
      //   handle: () => {}
      // },
      {
        keyCode: 49, // Number 1
        handle: () => {}
      },
      {
        keyCode: 37, // Left arrow
        ctrl: false, // Ctrl/Cmd
        handle: () => {}
      },
      {
        keyCode: 38, // Up arrow
        handle: () => {}
      },
      {
        keyCode: 39, // Right arrow
        ctrl: false, // Ctrl/Cmd
        handle: () => {}
      },
      {
        keyCode: 40, // Right arrow
        handle: () => {}
      }
    ];
  
    render() {
      if (!isMobileOnly) return (
        <Player ref={this.videoRef} muted autoPlay disableDefaultControls fluid loop src={videomp4} onClick={(e) => {e.stopPropagation()}} onPlay={(e) => {this.setVideoTimeout()}} onPause={(e) => {clearTimeout(this.timer)}}>
          <BigPlayButton position="center" />
          <LoadingSpinner />
          <Shortcut clickable={true} dblclickable={false} shortcuts={this.newShortcuts} />
        </Player>); 
      else return (
        // <video preload="metadata" src="/assets/ok.mp4" width="800" height="400">
        //   <source src="/assets/ok.mp4" type="video/mp4" />
        //   <source src={okwebm} type="video/webm" />
        //   Your browser does not support the video tag. I suggest you upgrade your browser.
        // </video>
        <img src={sognolucido_mobile} alt="sogno_lucido_background" className="background-image-mobile"></img>
      );
    }
}

export {BackgroundVideoComponent};