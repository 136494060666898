import { ImageBean } from "./imageBean";

export class ProjectBean {
    private readonly projectId: string;
    private readonly projectName: string;
    private readonly projectDate: Date;
    private readonly projectImage: ImageBean;
    
    constructor(projectId: string, projectName: string, projectDate: Date, projectImage : ImageBean) {
        this.projectId = projectId;
        this.projectName = projectName;
        this.projectDate = projectDate;
        this.projectImage = projectImage;
    }
        
    get getProjectId() : string {
        return this.projectId;
    }
        
    get getProjectName() : string {
        return this.projectName;
    }
    
    get getProjectDate() : Date {
        return this.projectDate;
    }
    
    get getProjectImg() : ImageBean {
        return this.projectImage;
    }
}