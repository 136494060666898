
import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Modal from "react-bootstrap/Modal";
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { GLTFModel, AmbientLight, DirectionLight } from 'react-3d-viewer';
import { MenuComponent } from '../../common/menu/menuComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Spinner } from 'react-bootstrap';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import head from '../../models/head.gltf';
import clothes from '../../models/clothes.gltf';

interface DigitalProps {
}

interface DigitalState {
    isOpen : boolean;
}

class Digital extends React.Component<DigitalProps, DigitalState> {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    setIsOpen(boolModalShow) {
        this.setState({isOpen: boolModalShow});
    }

    showModal = () => {
        this.setIsOpen(true);
    }
    
    hideModal = () => {
        this.setIsOpen(false);
    }

    hiddenSpinnerOnLoad(spinnerId : string) {
        let spinner = document.getElementById(spinnerId);
        spinner?.classList.add("hidden");
    }

    handleOnLoad1 = () => {
        this.hiddenSpinnerOnLoad("spinnerOnLoad_1");
    }

    handleOnLoad2 = () => {
        this.hiddenSpinnerOnLoad("spinnerOnLoad_2");
    }

    componentDidMount () {
        window.scrollTo(0, 0);
    }
  
    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                    <Col xs={2} sm={2} className="col-social-container">
                        <SocialIconsComponent actualPage={"digital"} />
                    </Col>
                    <Col xs={10} sm={8}>
                        <Modal show={this.state.isOpen} onHide={this.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <ModalHeader closeButton>
                                <ModalTitle id="contained-modal-title-vcenter">Particles</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                <iframe className="embed-responsive embed-responsive-16by9" title="Particles" src="https://player.vimeo.com/video/428851297" frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
                            </ModalBody>
                            <ModalFooter>
                                <p> Digital Artists: Benedetta Vangi, Jessica Cheli
                                    <br />
                                    Production, 3D and Art Direction by Benedetta Vangi and Jessica Cheli 
                                </p>
                            </ModalFooter>
                        </Modal>
                        <Row style={{marginTop: "5em", marginBottom: "5em"}}>
                            <Col xs={12} md={6}>
                                <div className="canvas-3d">
                                    <div className="linkToVideo fa-pulse" onClick={this.showModal}>
                                        <span style={{textTransform: "uppercase", marginRight: "10px"}}>Click here to watch the video</span>
                                        <FontAwesomeIcon className="social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'angle-double-right' })} />
                                    </div>
                                    <Spinner style={{margin: "20% auto"}} id="spinnerOnLoad_1" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
                                    <GLTFModel background="rgb(0,0,0)" src={head} width="300" height="300" onLoad={this.handleOnLoad1}>
                                        <AmbientLight color={0xffffff} />
                                        <DirectionLight color={0xffffff} position={{x:100,y:200,z:100}}/>
                                        <DirectionLight color={0xffffff} position={{x:-100,y:200,z:-100}}/>
                                    </GLTFModel>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="canvas-3d">
                                    <div className="linkToVideo" onClick={this.showModal}>
                                        <span style={{textTransform: "lowercase", marginRight: "10px"}}>work in progress</span>
                                    </div>
                                    <Spinner style={{margin: "20% auto"}} id="spinnerOnLoad_2" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
                                    <GLTFModel background="rgb(0,0,0)" src={clothes} width="300" height="300" onLoad={this.handleOnLoad2} >
                                    </GLTFModel>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={0} sm={2}>
                        <MenuComponent isMobile={false} />
                    </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Digital};