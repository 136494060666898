import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import aboutStars from '../../images/ABOUTSTARS.png';
// import mydrawAbout from '../../images/mydrawAbout.png';
// import skills from '../../images/SKILLS.png';

class About extends React.Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={2} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"about"} />
                        </Col>
                        <Col xs={10} sm={8}>
                            <Container fluid className="centralContainer">
                                <Row>
                                    <Col xs={12}>
                                    <img src={aboutStars} alt="About Me - Soft Skills" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={5} className="aboutTitle">
                                        <Row>
                                            <p> « An archive of sensory experiences and fashion. » </p>
                                        </Row>
                                        {/* <Row>
                                            <img src={mydrawAbout} alt="About Me - My Drawing" />
                                        </Row> */}
                                    </Col>
                                    <Col xs={12} md={7} className="aboutDescription">
                                        <Row>
                                            <Col xs={12}>
                                                <p> About </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={9} md={8}>
                                                <p> 
                                                    Avant-garde fashion stylist and art director fond of digital and multisensorial experience. Obsessed with turning sensations into storytelling.
                                                    <br /> 
                                                    VNG — B designer and art director with a focus on UX interactive design and digital experience. LV’s raised it up.
                                                    <br /> 
                                                    2020, Istituto Marangoni — Firenze
                                                    <br /> 
                                                    Graduated in Fashion Styling and Creative Direction
                                                </p>
                                            </Col>
                                            <Col xs={3} md={4}>
                                                <p> ITALY, 21 </p>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <img src={skills} alt="About Me - Hard Skills" />
                                        </Row> */}
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={0} sm={2}>
                            <MenuComponent isMobile={false}/>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {About};