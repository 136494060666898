import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from "react-router-dom";
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import { stopFixedPositionAt } from '../../utils/checkFixedPosition';
import { ProjectsViewFormat } from '../../beans/projectsViewFormat';
import gridViewStrokeIcon from '../../images/gridview_stroke.png';
import gridViewFillIcon from '../../images/gridview_fill.png';
import listViewStrokeIcon from '../../images/listview_stroke.png';
import listViewFillIcon from '../../images/listview_fill.png';


library.add(fab, fas);

interface SocialIconsComponentProps {
    actualPage: string
    onViewChange?: ((projectsView : ProjectsViewFormat) => void);
}

interface SocialIconsComponentState {
  greaterThan: boolean;
  lessThan: boolean;
  projectsView: ProjectsViewFormat;
}


class SocialIconsComponent extends React.Component<SocialIconsComponentProps, SocialIconsComponentState> {

    isActualPageHomepage() : boolean {
      return (this.props.actualPage === "homepage");
    }
  
    isActualPageProject() : boolean {
      return (this.props.actualPage === "projects");
    }
  
    social = {
      instagram: {
        icon: findIconDefinition({ prefix: 'fab', iconName: 'instagram' }),
        link: 'https://www.instagram.com/benevangi'
      },
      facebook: {
        icon: findIconDefinition({ prefix: 'fab', iconName: 'facebook-f' }),
        link: 'https://www.facebook.com/benedetta.vangi.5'
      },
      linkedin: {
        icon: findIconDefinition({ prefix: 'fab', iconName: 'linkedin-in' }),
        link: 'https://www.linkedin.com/in/benedetta-vangi'
      }
    }
  
    state = {
      greaterThan: false,
      lessThan: false,
      projectsView: ProjectsViewFormat.List
    }
  
    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('touchmove', this.handleScroll);
    }
  
    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('touchmove', this.handleScroll);
    }
  
    handleScroll = (event) => {
      let myMap = stopFixedPositionAt(".social-icons-container", "footer", 150);
      this.setState({greaterThan: myMap?.greaterThan, lessThan: myMap?.lessThan});
    }

    handleGridListClick = (e) => {
      let tempProjectsView = this.state.projectsView;
      if (this.state.projectsView === ProjectsViewFormat.List) {
        tempProjectsView = ProjectsViewFormat.Grid;
      } else {
        tempProjectsView = ProjectsViewFormat.List;
      }

      this.setState({projectsView : tempProjectsView});
      if (this.props.onViewChange) {
        this.props.onViewChange(tempProjectsView);
      }
    }
  
    render() {
        let className = "social-icons-container";
        if (this.state.greaterThan){
            className += " social-icons-container-absolute";
        } else if (this.state.lessThan){
            className += " social-icons-container-fixed";
        }
        return (
            <div id="social-container" className={className}>
                <NavLink exact to="/experience">
                  <h5 className={(this.isActualPageHomepage() ? '' : 'hidden')}>LIVE AN EXPERIENCE</h5>
                </NavLink>
                <div className={(this.isActualPageProject() ? 'projectsView' : 'hidden')}>
                  {(this.state.projectsView === ProjectsViewFormat.List) ? (
                    <div>
                      <a style={{display: "block", float: "left"}} href="#grid"><img onClick={this.handleGridListClick} className="social-icon-half" src={gridViewStrokeIcon} alt="GridViewIcon" /></a>
                      <a style={{display: "block", float: "right"}} href="#list"><img onClick={(isMobileOnly) ? this.handleGridListClick : undefined} className="social-icon-half" src={listViewFillIcon} alt="ListViewIcon" /></a>
                    </div>
                  ) : (
                    <div>
                      <a style={{display: "block", float: "left"}} href="#grid"><img onClick={(isMobileOnly) ? this.handleGridListClick : undefined} className="social-icon-half" src={gridViewFillIcon} alt="GridViewIcon" /></a>
                      <a style={{display: "block", float: "right"}} href="#list"><img onClick={this.handleGridListClick} className="social-icon-half" src={listViewStrokeIcon} alt="ListViewIcon" /></a>
                    </div>
                  )
                  }
                </div>
                <div className={"vertical-line " + (this.isActualPageHomepage() ? '' : 'vertical-line-no-margin')}></div>
                { Object.keys(this.social).map((item, index) => {
                    return <a key={index} href={this.social[item].link} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon className="social-icon" icon={this.social[item].icon} /></a>;
                })}
            </div>
        )
    }
}

export {SocialIconsComponent};