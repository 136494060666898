import { ParagraphBean } from "./paragraphBean";

export class ChapterBean {
    private readonly chapterId: string;
    private readonly title: string;
    private readonly paragraphs: ParagraphBean[];

    constructor(chapterId: string, title : string, paragraphs : ParagraphBean[]) {
        this.chapterId = chapterId;
        this.title = title;
        this.paragraphs = paragraphs;
    }

    get getChapterId() : string {
        return this.chapterId;
    }

    get getTitle() : string {
        return this.title;
    }

    get getParagraphs() : ParagraphBean[] {
        return this.paragraphs;
    }
}