import * as React from 'react';
import { Spinner } from 'react-bootstrap';

interface InitialLoadingPageProps {
    timeDurationTypo: number,
    timeDurationTimeout : number,
    onLoading: ((loadingParam : boolean) => void);
}

interface InitialLoadingPageState {}

class InitialLoadingPage extends React.Component<InitialLoadingPageProps, InitialLoadingPageState> {
    text = "Compiling the code...<br>" +
    "Loading custom css...<br>" +
    "Loading custom fonts...<br>" +
    "Setting up the environment...<br>" +
    "Creating an executable program from code...<br>" +
    "<span style=\"color:#4BB543;\">Compiled successfully!</span><br>" + 
    "You can now view vangibenedetta.com in the browser.";
    timer!: NodeJS.Timeout;

    componentDidMount() {
        this.startEffect();
        this.timer = setTimeout(() => {
            this.props.onLoading(false);
        },  this.props.timeDurationTimeout);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    typoEffect(fieldId : string, text : string, speed : number, position ?: number){
        let checkField = document.getElementById(fieldId);
        if ((checkField === null))
            return ;
        let length : number = text.length;
        position = position || 0;
        if (position < length){
            var char = text.substring(position, position + 1);
            if (char === '<' && text.substring(position + 1, position + 2) === 'b') {
                checkField.innerHTML = "<p>" + text.substring(0, position) + "</p>"
                setTimeout(() => this.typoEffect(fieldId, text, speed, position as number + 4), speed);
            } else {
                checkField.innerHTML = text.substring(0, position) + char + "_";
                setTimeout(() => this.typoEffect(fieldId, text, speed, position as number + 1), speed);
            }     
            //setTimeout(() => this.typoEffect(fieldId, text, speed, position as number + 1), speed);
        } else {
            checkField.innerHTML = checkField.innerHTML.substring(0, length);
        }
    }

    startEffect(){
        // let myText : string = this.text.replace(/\s+/g, " ");
        let myText : string = this.text;
        this.typoEffect('initialCode', myText, this.props.timeDurationTypo);    
    }

    render() {
        return (
            <div id="loadingDiv">
                <Spinner id="initialSpinner" animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>
                <div id='initialCode'></div>
            </div>
        );
    }
}

export {InitialLoadingPage};

