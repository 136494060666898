import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import { BackgroundVideoComponent } from '../../common/visual/backgroundVideoComponent';

class Homepage extends React.Component {

    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row style={{height: '50em'}}>
                        <Col xs={2} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"homepage"} />
                        </Col>
                        <Col xs={10} sm={8} style={{height: '42em'}}>
                            <div className="projects">
                                <NavLink exact to="/projects"> PRJ+ </NavLink>
                            </div>
                            <BackgroundVideoComponent />
                        </Col>
                        <Col xs={0} sm={2}>
                            <MenuComponent isMobile={false} />
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Homepage};