import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import { homePath } from '../../utils/homePath';
import logo from '../../images/logo.png';


class HeaderComponent extends React.Component {

    handleMouseOver = (e) => {
        $('.header .header-brand img').addClass('imageRotate');
    }

    handleMouseLeave = (e) => {
        $('.header .header-brand img').removeClass('imageRotate');
    }

    render() {
        return (
            <Navbar className="header">
            <Navbar.Brand href={homePath + "/"} className="header-brand" onMouseLeave={this.handleMouseLeave} onMouseOver={this.handleMouseOver}>
                VNG
                <img alt="Logo_VNG-B" src={logo} width="22" height="22" className="d-inline-block align-top"/>
                B
            </Navbar.Brand>
            </Navbar>
        )
    }
}

export {HeaderComponent};