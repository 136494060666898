import { ProjectBean } from "./projectBean";
import { ProjectDetailsBean } from "./projectDetailsBean";
import { LocationBean } from "./locationBean";
import { ImageBean } from "./imageBean";
import { VideoBean } from "./videoBean";
import { ParagraphBean } from "./paragraphBean";

export class ProjectDetailsBuilder {

    private readonly projectBean: ProjectBean;
    
    private location: LocationBean | undefined = undefined;
    private profileImage: ImageBean | undefined = undefined;
    private title: string | undefined = undefined;
    private paragraphs: ParagraphBean[] | undefined = undefined;
    private bulletText: string[] | undefined = undefined;
    private credits: string | undefined = undefined;
    private linkToRelease: string | undefined = undefined;
    private horizontalScrollImages: ImageBean[] | undefined = undefined;
    private video: VideoBean | undefined = undefined;
    private firstVerticalImage: ImageBean | undefined = undefined;
    private secondVerticalImage: ImageBean | undefined = undefined;
    private horizontalImage: ImageBean | undefined = undefined;
    private scrollToViewImages: ImageBean[] | undefined = undefined;
    private fashion: string | undefined = undefined;
    private production: string | undefined = undefined;

    constructor(projectBean: ProjectBean) {
        this.projectBean = projectBean;
    }

    setLocation(location: LocationBean) {
        this.location = location;
        return this;
    }

    setProfileImage(profileImage : ImageBean) {
        this.profileImage = profileImage;
        return this;
    }

    setTitle(title : string) {
        this.title = title;
        return this;
    }

    setParagraphs(paragraphs : ParagraphBean[]) {
        this.paragraphs = paragraphs;
        return this;
    }

    setBulletText(bulletText : string[]) {
        this.bulletText = bulletText;
        return this;
    }

    setCredits(credits : string) {
        this.credits = credits;
        return this;
    }

    setLinkToRelease(linkToRelease : string) {
        this.linkToRelease = linkToRelease;
        return this;
    }

    setHorizontalScrollImages(horizontalScrollImages : ImageBean[]) {
        this.horizontalScrollImages = horizontalScrollImages;
        return this;
    }

    setVideo(video : VideoBean) {
        this.video = video;
        return this;
    }

    setFirstVerticalImage(firstVerticalImage : ImageBean) {
        this.firstVerticalImage = firstVerticalImage;
        return this;
    }

    setSecondVerticalImage(secondVerticalImage : ImageBean) {
        this.secondVerticalImage = secondVerticalImage;
        return this;
    }

    setHorizontalImage(horizontalImage : ImageBean) {
        this.horizontalImage = horizontalImage;
        return this;
    }

    settscrollToViewImages(scrollToViewImages : ImageBean[]) {
        this.scrollToViewImages = scrollToViewImages;
        return this;
    }

    setFashion(fashion : string) {
        this.fashion = fashion;
        return this;
    }

    setProduction(production : string) {
        this.production = production;
        return this;
    }

    build() {
        return new ProjectDetailsBean(this);
    }

    get getProjectBean() : ProjectBean {
        return this.projectBean;
    }

    get getLocation() : LocationBean|undefined {
        return this.location;
    }

    get getProfileImage() : ImageBean|undefined {
        return this.profileImage;
    }

    get getTitle() : string|undefined {
        return this.title;
    }

    get getParagraphs() : ParagraphBean[]|undefined {
        return this.paragraphs;
    }

    get getBulletText() : string[]|undefined {
        return this.bulletText;
    }

    get getCredits() : string|undefined {
        return this.credits;
    }

    get getLinkToRelease() : string|undefined {
        return this.linkToRelease;
    }

    get getHorizontalScrollImages() : ImageBean[]|undefined {
        return this.horizontalScrollImages;
    }

    get getVideo() : VideoBean|undefined {
        return this.video;
    }

    get getFirstVerticalImage() : ImageBean|undefined {
        return this.firstVerticalImage;
    }

    get getSecondVerticalImage() : ImageBean|undefined {
        return this.secondVerticalImage;
    }

    get getHorizontalImage() : ImageBean|undefined {
        return this.horizontalImage;
    }

    get getscrollToViewImages() : ImageBean[]|undefined {
        return this.scrollToViewImages;
    }

    get getFashion() : string|undefined {
        return this.fashion;
    }

    get getProduction() : string|undefined {
        return this.production;
    }
}