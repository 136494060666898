export class ParagraphBean {
    private title: string;
    private text: string;

    constructor(title : string, text : string) {
        this.title = title;
        this.text = text;
    }

    get getTitle() : string {
        return this.title;
    }

    get getText() : string {
        return this.text;
    }
}