import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import { ChapterServiceManager } from '../../services/chapterServiceManager';
import { ChapterBean } from '../../beans/chapterBean';


interface ProjectComponentProps {
    chapterService : ChapterServiceManager;
}

interface ProjectComponentState {
    chapters : Map<string, ChapterBean>;
}

class Philosophy extends React.Component<ProjectComponentProps, ProjectComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            chapters : new Map<string, ChapterBean>()
        }
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this.setState({chapters: this.props.chapterService.loadChapters()});
    }

    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={2} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"philosophy"} />
                        </Col>
                        <Col xs={10} sm={8}>
                            <Container fluid className="philosophy-container">
                                <Row>
                                    <Col xs={0} md={6}></Col>
                                    <Col xs={12} md={6}>
                                        <Row className="philosophy-title-row">
                                            <p>Synesthesia.</p>
                                            <p>The influence of multi-sensorial connection and experiential marketing on luxury and fashion system in contemporary and foreseeable future.</p>
                                        </Row>
                                        <Row className="philosophy-text-row">
                                            <Col xs={9} md={8}>
                                                <blockquote>
                                                    <p><q>I carried to my lips a spoonful of the tea in which I had let soften a bit of madeleine. But at the very instant when the mouthful of tea mixed with cake crumbs touched my palate, I quivered, attentive to the extraordinary thing that was happening inside me.</q></p>
                                                    <footer>«À la recherche du temps perdu», Marcel Proust 1913</footer>
                                                </blockquote>
                                            </Col>
                                            <Col xs={3} md={4}>
                                                <p> 1913 </p>
                                            </Col>
                                        </Row>
                                        <Row className="introduction-next">
                                            <Link className="fa-pulse" to={"/philosophy/" + this.state.chapters.keys().next().value} >
                                                <span style={{textTransform: "capitalize"}}>chapter 1 - {this.state.chapters.keys().next().value}</span>
                                                <FontAwesomeIcon className="social-icon" icon={findIconDefinition({ prefix: 'fas', iconName: 'angle-double-right' })} />
                                            </Link>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={0} sm={2}>
                            <MenuComponent isMobile={false}/>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Philosophy};