import * as React from 'react';

interface MouseComponentProps {
    DivAppRef : React.RefObject<HTMLDivElement>
}

class MouseComponent extends React.Component<MouseComponentProps, any> {
    state = {
        mouseWidth: 30,
        mouseHeight: 30,
        mouseX: -100,
        mouseY: -100,
        lastScrolledTop: 0,
        transition: "none",
        opacity: 0.5
    };

    componentDidMount() {
        var self = this;
        $(document).on('mouseenter', '#div-app', function (event) {
            self.handleMouseLocation(event.pageX, event.pageY);
        });
        $(document).on('mousemove', '#div-app', function (event) {
            self.handleMouseLocation(event.pageX, event.pageY);
            self.handleMouseSize();
        });
        $(document).on('mouseleave', '#div-app', function (event) {
            self.setState({mouseWidth: 0, mouseHeight: 0});
        });
        $(window).scroll(function() {
            self.handleMouseOnScroll(document.documentElement.scrollTop);
        });
    }

    componentWillUnmount() {
        $(document).off('mouseenter');
        $(document).off('mousemove');
        $(document).off('mouseleave');
    }

    handleMouseLocation(x : number, y : number) {
        this.setState({mouseX: x, mouseY: y});
    }

    handleMouseSize() {
        if($("a[href]:hover").length !== 0){
            this.setState({mouseWidth: 60, mouseHeight: 60});
            this.setState({transition: "all 200ms ease"});
            this.setState({opacity: 1});
        } else {
            this.setState({mouseWidth: 30, mouseHeight: 30});
            this.setState({transition: "none"})
            this.setState({opacity: 0.5});
        }
    }

    handleMouseOnScroll(scrollTop : number) {
        if(this.state.lastScrolledTop !== scrollTop){
            let yMousePos = this.state.mouseY - this.state.lastScrolledTop;
            this.setState({lastScrolledTop: scrollTop});
            yMousePos = yMousePos + this.state.lastScrolledTop;
            this.setState({mouseY: yMousePos});
        }
    }
  
    // trace = (a : number, b : number, n) => {
    //   return (1 - n) * a + n * b;
    // }
  
    render() {
        return (
            <div className="custom-pointer" style={{visibility: "inherit", opacity: this.state.opacity, transform: `translate3d(${this.state.mouseX - this.state.mouseWidth/2 + 4.5}px, ${this.state.mouseY - this.state.mouseHeight/2 + 0.75}px, 0px)`, width: this.state.mouseWidth, height: this.state.mouseHeight, transition: this.state.transition}}>
                <svg height="100%" width="100%" className="circle-pointer">
                    <circle cx="50%" cy="50%" r="45%" strokeWidth="2" fill="none" className="basic-circle-pointer"></circle>
                    <circle cx="50%" cy="50%" r="45%" strokeWidth="2" fill="none" strokeDasharray="285%" strokeDashoffset="285%" className="circle-content-pointer"></circle>
                </svg>
                {/* <div id="pointer-ring" ></div>
                <div id="pointer-dash" style={{transform: `translate(${this.state.mouseX}px, ${this.state.mouseY}px) scale(0.8)`}}></div> */}
            </div>
        );
    }
}

export {MouseComponent};