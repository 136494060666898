export function stopFixedPositionAt (divFixedName : string, divContainerStopAt : string, divSpread : number) {
    let divContainer = $(divFixedName);
    let footer = $(divContainerStopAt);
  
    var myMap = ({greaterThan: false, lessThan: false});
  
    if (divContainer !== undefined && footer !== undefined) {
  
      let containerOffsetTop : number = divContainer.offset()?.top as number;
      let containerHeight : number = divContainer.height() as number;
      let footerOffsetTop : number = footer.offset()?.top as number;
      let spread = divSpread;
  
      if (containerOffsetTop + containerHeight >= footerOffsetTop - spread){
        myMap = ({greaterThan: true, lessThan: false});
      }
      
      if (document.documentElement.scrollTop + containerHeight < footerOffsetTop - spread){
        myMap = ({greaterThan: false, lessThan: true});
      }
    
    }
    return myMap; 
}