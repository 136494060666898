import { ProjectBean } from "../beans/projectBean";
import { ImageBean } from "../beans/imageBean";
import { VideoBean } from "../beans/videoBean";
import { VideoFormat } from "../beans/videoFormat";
import { ProjectDetailsBean } from "../beans/projectDetailsBean";
import { ProjectDetailsBuilder } from "../beans/projectDetailsBuilder";
import { LocationBean } from "../beans/locationBean";
import { Location } from "../beans/locationBean";
import { ParagraphBean } from "../beans/paragraphBean";

import slide0 from '../projects-contents/scrollright.jpg';
import slide0_mobile from '../projects-contents/scrollright_mobile.jpg';

/* -- INSIDE YOUR BLOOD -- */
import insideyourblood_profileImageImg from '../projects-contents/20190513_insideyourblood/insideyourblood/00_insideyourblood.jpg';
import insideyourblood_firstVerticalImg from '../projects-contents/20190513_insideyourblood/insideyourblood/06_insideyourblood.jpg';
import insideyourblood_secondVerticalImg from '../projects-contents/20190513_insideyourblood/insideyourblood/07_insideyourblood.jpg';
import insideyourblood_horizontalImg from '../projects-contents/20190513_insideyourblood/insideyourblood/08_insideyourblood.jpg';

import insideyourblood_slide1 from '../projects-contents/20190513_insideyourblood/insideyourblood/01_insideyourblood.jpg';
import insideyourblood_slide2 from '../projects-contents/20190513_insideyourblood/insideyourblood/02_insideyourblood.jpg';
import insideyourblood_slide3 from '../projects-contents/20190513_insideyourblood/insideyourblood/03_insideyourblood.jpg';
import insideyourblood_slide4 from '../projects-contents/20190513_insideyourblood/insideyourblood/04_insideyourblood.jpg';
import insideyourblood_slide5 from '../projects-contents/20190513_insideyourblood/insideyourblood/05_insideyourblood.jpg';

import insideyourblood_scrollImg0 from '../projects-contents/20190513_insideyourblood/insideyourblood/09_insideyourblood.jpg';
import insideyourblood_scrollImg1 from '../projects-contents/20190513_insideyourblood/insideyourblood/10_insideyourblood.jpg';
import insideyourblood_scrollImg2 from '../projects-contents/20190513_insideyourblood/insideyourblood/11_insideyourblood.jpg';
import insideyourblood_scrollImg3 from '../projects-contents/20190513_insideyourblood/insideyourblood/12_insideyourblood.jpg';
import insideyourblood_scrollImg4 from '../projects-contents/20190513_insideyourblood/insideyourblood/13_insideyourblood.jpg';
import insideyourblood_scrollImg5 from '../projects-contents/20190513_insideyourblood/insideyourblood/14_insideyourblood.jpg';
import insideyourblood_scrollImg6 from '../projects-contents/20190513_insideyourblood/insideyourblood/15_insideyourblood.jpg';
import insideyourblood_scrollImg7 from '../projects-contents/20190513_insideyourblood/insideyourblood/16_insideyourblood.jpg';
import insideyourblood_scrollImg8 from '../projects-contents/20190513_insideyourblood/insideyourblood/17_insideyourblood.jpg';
import insideyourblood_scrollImg9 from '../projects-contents/20190513_insideyourblood/insideyourblood/18_insideyourblood.jpg';
/* -- ----------------- -- */

/* -- GENDERLESS -- */
import genderless_profileImageImg from '../projects-contents/20190416_genderless/genderless/00_genderless.jpg';
import genderless_firstVerticalImg from '../projects-contents/20190416_genderless/genderless/06_genderless.jpg';
import genderless_secondVerticalImg from '../projects-contents/20190416_genderless/genderless/07_genderless.jpg';
import genderless_horizontalImg from '../projects-contents/20190416_genderless/genderless/08_genderless.jpg';

import genderless_slide1 from '../projects-contents/20190416_genderless/genderless/01_genderless.jpg';
import genderless_slide2 from '../projects-contents/20190416_genderless/genderless/02_genderless.jpg';
import genderless_slide3 from '../projects-contents/20190416_genderless/genderless/03_genderless.jpg';
import genderless_slide4 from '../projects-contents/20190416_genderless/genderless/04_genderless.jpg';
import genderless_slide5 from '../projects-contents/20190416_genderless/genderless/05_genderless.jpg';

import genderless_scrollImg0 from '../projects-contents/20190416_genderless/genderless/09_genderless.jpg';
import genderless_scrollImg1 from '../projects-contents/20190416_genderless/genderless/10_genderless.jpg';
import genderless_scrollImg2 from '../projects-contents/20190416_genderless/genderless/11_genderless.jpg';
import genderless_scrollImg3 from '../projects-contents/20190416_genderless/genderless/12_genderless.jpg';
import genderless_scrollImg4 from '../projects-contents/20190416_genderless/genderless/13_genderless.jpg';
import genderless_scrollImg5 from '../projects-contents/20190416_genderless/genderless/14_genderless.jpg';
import genderless_scrollImg6 from '../projects-contents/20190416_genderless/genderless/15_genderless.jpg';
import genderless_scrollImg7 from '../projects-contents/20190416_genderless/genderless/16_genderless.jpg';
import genderless_scrollImg8 from '../projects-contents/20190416_genderless/genderless/17_genderless.jpg';
import genderless_scrollImg9 from '../projects-contents/20190416_genderless/genderless/18_genderless.jpg';
import genderless_scrollImg10 from '../projects-contents/20190416_genderless/genderless/19_genderless.jpg';
import genderless_scrollImg11 from '../projects-contents/20190416_genderless/genderless/20_genderless.jpg';
/* -- ----------------- -- */

/* -- DIGGING LIGHT -- */
import digginglight_profileImageImg from '../projects-contents/20190928_digginglight/digginglight/00_digginglight.jpg';
import digginglight_firstVerticalImg from '../projects-contents/20190928_digginglight/digginglight/06_digginglight.jpg';
import digginglight_secondVerticalImg from '../projects-contents/20190928_digginglight/digginglight/07_digginglight.jpg';
import digginglight_horizontalImg from '../projects-contents/20190928_digginglight/digginglight/08_digginglight.jpg';

import digginglight_slide1 from '../projects-contents/20190928_digginglight/digginglight/01_digginglight.jpg';
import digginglight_slide2 from '../projects-contents/20190928_digginglight/digginglight/02_digginglight.jpg';
import digginglight_slide3 from '../projects-contents/20190928_digginglight/digginglight/03_digginglight.jpg';
import digginglight_slide4 from '../projects-contents/20190928_digginglight/digginglight/04_digginglight.jpg';
import digginglight_slide5 from '../projects-contents/20190928_digginglight/digginglight/05_digginglight.jpg';

import digginglight_scrollImg0 from '../projects-contents/20190928_digginglight/digginglight/09_digginglight.jpg';
import digginglight_scrollImg1 from '../projects-contents/20190928_digginglight/digginglight/10_digginglight.jpg';
import digginglight_scrollImg2 from '../projects-contents/20190928_digginglight/digginglight/11_digginglight.jpg';
import digginglight_scrollImg3 from '../projects-contents/20190928_digginglight/digginglight/12_digginglight.jpg';
import digginglight_scrollImg4 from '../projects-contents/20190928_digginglight/digginglight/13_digginglight.jpg';
import digginglight_scrollImg5 from '../projects-contents/20190928_digginglight/digginglight/14_digginglight.jpg';
import digginglight_scrollImg6 from '../projects-contents/20190928_digginglight/digginglight/15_digginglight.jpg';
import digginglight_scrollImg7 from '../projects-contents/20190928_digginglight/digginglight/16_digginglight.jpg';
import digginglight_scrollImg8 from '../projects-contents/20190928_digginglight/digginglight/17_digginglight.jpg';
import digginglight_scrollImg9 from '../projects-contents/20190928_digginglight/digginglight/18_digginglight.jpg';
/* -- ----------------- -- */

/* -- CRISALIDE -- */
import crisalide_profileImageImg from '../projects-contents/20191203_crisalide/crisalide/00_crisalide.jpg';
import crisalide_firstVerticalImg from '../projects-contents/20191203_crisalide/crisalide/06_crisalide.jpg';
import crisalide_secondVerticalImg from '../projects-contents/20191203_crisalide/crisalide/07_crisalide.jpg';
import crisalide_horizontalImg from '../projects-contents/20191203_crisalide/crisalide/08_crisalide.jpg';

import crisalide_slide1 from '../projects-contents/20191203_crisalide/crisalide/01_crisalide.jpg';
import crisalide_slide2 from '../projects-contents/20191203_crisalide/crisalide/02_crisalide.jpg';
import crisalide_slide3 from '../projects-contents/20191203_crisalide/crisalide/03_crisalide.jpg';
import crisalide_slide4 from '../projects-contents/20191203_crisalide/crisalide/04_crisalide.jpg';
import crisalide_slide5 from '../projects-contents/20191203_crisalide/crisalide/05_crisalide.jpg';

import crisalide_scrollImg0 from '../projects-contents/20191203_crisalide/crisalide/09_crisalide.jpg';
import crisalide_scrollImg1 from '../projects-contents/20191203_crisalide/crisalide/10_crisalide.jpg';
import crisalide_scrollImg2 from '../projects-contents/20191203_crisalide/crisalide/11_crisalide.jpg';
import crisalide_scrollImg3 from '../projects-contents/20191203_crisalide/crisalide/12_crisalide.jpg';
import crisalide_scrollImg4 from '../projects-contents/20191203_crisalide/crisalide/13_crisalide.jpg';
import crisalide_scrollImg5 from '../projects-contents/20191203_crisalide/crisalide/14_crisalide.jpg';
import crisalide_scrollImg6 from '../projects-contents/20191203_crisalide/crisalide/15_crisalide.jpg';
import crisalide_scrollImg7 from '../projects-contents/20191203_crisalide/crisalide/16_crisalide.jpg';
import crisalide_scrollImg8 from '../projects-contents/20191203_crisalide/crisalide/17_crisalide.jpg';
import crisalide_scrollImg9 from '../projects-contents/20191203_crisalide/crisalide/18_crisalide.jpg';
/* -- ----------------- -- */

/* -- SOGNO LUCIDO. RICERCA ESTETICA -- */
import sognolucido_profileImageImg from '../projects-contents/20190828_sognolucido/sognolucido/00_sognolucido.jpg';
import sognolucido_firstVerticalImg from '../projects-contents/20190828_sognolucido/sognolucido/06_sognolucido.jpg';
import sognolucido_secondVerticalImg from '../projects-contents/20190828_sognolucido/sognolucido/07_sognolucido.jpg';
import sognolucido_horizontalImg from '../projects-contents/20190828_sognolucido/sognolucido/08_sognolucido.jpg';

import sognolucido_slide1 from '../projects-contents/20190828_sognolucido/sognolucido/01_sognolucido.jpg';
import sognolucido_slide2 from '../projects-contents/20190828_sognolucido/sognolucido/02_sognolucido.jpg';
import sognolucido_slide3 from '../projects-contents/20190828_sognolucido/sognolucido/03_sognolucido.jpg';
import sognolucido_slide4 from '../projects-contents/20190828_sognolucido/sognolucido/04_sognolucido.jpg';
import sognolucido_slide5 from '../projects-contents/20190828_sognolucido/sognolucido/05_sognolucido.jpg';

import sognolucido_scrollImg0 from '../projects-contents/20190828_sognolucido/sognolucido/09_sognolucido.jpg';
import sognolucido_scrollImg1 from '../projects-contents/20190828_sognolucido/sognolucido/10_sognolucido.jpg';
import sognolucido_scrollImg2 from '../projects-contents/20190828_sognolucido/sognolucido/11_sognolucido.jpg';
import sognolucido_scrollImg3 from '../projects-contents/20190828_sognolucido/sognolucido/12_sognolucido.jpg';
import sognolucido_scrollImg4 from '../projects-contents/20190828_sognolucido/sognolucido/13_sognolucido.jpg';
import sognolucido_scrollImg5 from '../projects-contents/20190828_sognolucido/sognolucido/14_sognolucido.jpg';
import sognolucido_scrollImg6 from '../projects-contents/20190828_sognolucido/sognolucido/15_sognolucido.jpg';
import sognolucido_scrollImg7 from '../projects-contents/20190828_sognolucido/sognolucido/16_sognolucido.jpg';
/* -- ----------------- -- */

/* -- AND IN VAIN THE DREAMER? -- */
import andinvainthedreamer_profileImageImg from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/00_andinvainthedreamer.jpg';
import andinvainthedreamer_firstVerticalImg from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/06_andinvainthedreamer.jpg';
import andinvainthedreamer_secondVerticalImg from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/07_andinvainthedreamer.jpg';
import andinvainthedreamer_horizontalImg from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/08_andinvainthedreamer.jpg';

import andinvainthedreamer_slide1 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/01_andinvainthedreamer.jpg';
import andinvainthedreamer_slide2 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/02_andinvainthedreamer.jpg';
import andinvainthedreamer_slide3 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/03_andinvainthedreamer.jpg';
import andinvainthedreamer_slide4 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/04_andinvainthedreamer.jpg';
import andinvainthedreamer_slide5 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/05_andinvainthedreamer.jpg';

import andinvainthedreamer_scrollImg0 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/09_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg1 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/10_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg2 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/11_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg3 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/12_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg4 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/13_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg5 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/14_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg6 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/15_andinvainthedreamer.jpg';
import andinvainthedreamer_scrollImg7 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/16_andinvainthedreamer.jpg';
// import andinvainthedreamer_scrollImg8 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/17_andinvainthedreamer.jpg';
// import andinvainthedreamer_scrollImg9 from '../projects-contents/20190804_andinvainthedreamer/andinvainthedreamer/18_andinvainthedreamer.jpg';
/* -- ----------------- -- */

/* -- TEARS OF HAIR -- */
import tearsofhair_profileImageImg from '../projects-contents/20191118_tearsofhair/tearsofhair/00_tearsofhair.jpg';
import tearsofhair_firstVerticalImg from '../projects-contents/20191118_tearsofhair/tearsofhair/06_tearsofhair.jpg';
import tearsofhair_secondVerticalImg from '../projects-contents/20191118_tearsofhair/tearsofhair/07_tearsofhair.jpg';
import tearsofhair_horizontalImg from '../projects-contents/20191118_tearsofhair/tearsofhair/08_tearsofhair.jpg';

import tearsofhair_slide1 from '../projects-contents/20191118_tearsofhair/tearsofhair/01_tearsofhair.jpg';
import tearsofhair_slide2 from '../projects-contents/20191118_tearsofhair/tearsofhair/02_tearsofhair.jpg';
import tearsofhair_slide3 from '../projects-contents/20191118_tearsofhair/tearsofhair/03_tearsofhair.jpg';
import tearsofhair_slide4 from '../projects-contents/20191118_tearsofhair/tearsofhair/04_tearsofhair.jpg';
import tearsofhair_slide5 from '../projects-contents/20191118_tearsofhair/tearsofhair/05_tearsofhair.jpg';

import tearsofhair_scrollImg0 from '../projects-contents/20191118_tearsofhair/tearsofhair/09_tearsofhair.jpg';
import tearsofhair_scrollImg1 from '../projects-contents/20191118_tearsofhair/tearsofhair/10_tearsofhair.jpg';
import tearsofhair_scrollImg2 from '../projects-contents/20191118_tearsofhair/tearsofhair/11_tearsofhair.jpg';
import tearsofhair_scrollImg3 from '../projects-contents/20191118_tearsofhair/tearsofhair/12_tearsofhair.jpg';
import tearsofhair_scrollImg4 from '../projects-contents/20191118_tearsofhair/tearsofhair/13_tearsofhair.jpg';
import tearsofhair_scrollImg5 from '../projects-contents/20191118_tearsofhair/tearsofhair/14_tearsofhair.jpg';
/* -- ----------------- -- */

export interface IProjectServiceManager {
    loadProjects: () => Map<string, ProjectBean>;
    loadSingleProject: (projectId : string) => ProjectDetailsBean | undefined;
    getProjectsSize: () => number;
    getProjectIndex: (projectId : string) => number;
    getLinkPreviousProject: (projectId : string) => string | undefined;
    getLinkNextProject: (projectId : string) => string | undefined;
}


export class ProjectServiceManager implements IProjectServiceManager {
    private projectsMap : Map<string, ProjectBean>;

    constructor() {
        this.projectsMap = new Map<string, ProjectBean>();
    }

    public loadProjects() : Map<string, ProjectBean> {
        // load from DB or from projectsMap cache
        if (this.projectsMap.size === 0) {
            this.addProjectsToMap();
        }
        return this.projectsMap;
    }

    public loadSingleProject(projectId : string) : ProjectDetailsBean | undefined {
        if (this.projectsMap.size === 0) {
            this.addProjectsToMap();
        }
        if (this.projectsMap.get(projectId) === undefined)
            return undefined;
        return this.loadSingleProjectData(projectId, this.projectsMap.get(projectId) as ProjectBean);
    }

    // temporary private method
    private addProjectsToMap() {
        let bean : ProjectBean;

        bean = new ProjectBean("insideyourblood", "INSIDE YOUR BLOOD", new Date(2019, 4, 13, 0, 0, 0, 0), new ImageBean(insideyourblood_profileImageImg, "Inside Your Blood"));
        this.projectsMap.set("insideyourblood", bean);

        bean = new ProjectBean("andinvainthedreamer","AND IN VAIN THE DREAMER?", new Date(2019, 7, 4, 0, 0, 0, 0), new ImageBean(andinvainthedreamer_profileImageImg, "And In Vain The Dreamer"));
        this.projectsMap.set("andinvainthedreamer", bean);

        bean = new ProjectBean("genderless", "GENDERLESS", new Date(2019, 3, 16, 0, 0, 0, 0), new ImageBean(genderless_profileImageImg, "Genderless"));
        this.projectsMap.set("genderless", bean);

        bean = new ProjectBean("sognolucidoricercaestetica", "SOGNO LUCIDO. RICERCA ESTETICA", new Date(2019, 7, 28, 0, 0, 0, 0), new ImageBean(sognolucido_profileImageImg, "Sogno Lucido. Ricerca Estetica"))
        this.projectsMap.set("sognolucidoricercaestetica", bean);

        bean = new ProjectBean("digginglight", "DIGGING LIGHT", new Date(2019, 7, 28, 0, 0, 0, 0), new ImageBean(digginglight_profileImageImg, "Digging Light"));
        this.projectsMap.set("digginglight", bean);

        bean = new ProjectBean("tearsofhair", "TEARS OF HAIR", new Date(2019, 10, 18, 0, 0, 0, 0), new ImageBean(tearsofhair_profileImageImg, "Tears Of Hair"));
        this.projectsMap.set("tearsofhair", bean);

        bean = new ProjectBean("crisalide", "CRISALIDE", new Date(2019, 11, 3, 0, 0, 0, 0), new ImageBean(crisalide_profileImageImg, "Crisalide"));
        this.projectsMap.set("crisalide", bean);

        // bean = new ProjectBean("naturalselection", "NATURAL SELECTION", new Date(2019, 10, 25, 0, 0, 0, 0), new ImageBean(digginglight, "Natural Selection"));
        // this.projectsMap.set("naturalselection", bean);
    }

    // temporary private method
    private loadSingleProjectData(projectId : string, projectBean : ProjectBean) : ProjectDetailsBean | undefined {

        let projectDetailsBean : ProjectDetailsBean | undefined;
        let location : LocationBean | undefined;
        let profileImage: ImageBean | undefined;
        let title: string | undefined;
        let paragraphs: ParagraphBean[] | undefined;
        let bulletText: string[] | undefined;
        let linkToRelease: string | undefined;
        let credits: string | undefined;
        let horizontalScrollImages: ImageBean[] | undefined;
        let video: VideoBean | undefined;
        let firstVerticalImage: ImageBean | undefined;
        let secondVerticalImage: ImageBean | undefined;
        let horizontalImage: ImageBean | undefined;
        let scrollToViewImages: ImageBean[] | undefined;
        let fashion: string | undefined;
        let production: string | undefined;
        let slide0ImageBean = new ImageBean(slide0, "slide0");
        slide0ImageBean.setImageMobileSrc(slide0_mobile);

        switch(projectId) {
            case "insideyourblood": {
                location = new LocationBean("Via della Libertà, 50059 Vinci FI", new Location("43°46'27.0\"N", "10°57'09.7\"E"))
                profileImage = new ImageBean(insideyourblood_profileImageImg, "insideyourblood_profile");
                title = "Inside your blood";
                paragraphs = [new ParagraphBean("FLUID", "Fluid. Blood is where everything starts. Blood is where everything ends. Blood explores the individual through a biological point of view. Sangue, acre, dolce, puro, vero.")];
                bulletText = ["VERMILION", "GUSH", "VISCOUS", "ACRID", "SOUR"];
                credits = "Li by Leonardo Casalini styled by Benedetta Vangi and Jessica Cheli and groomed by Giulia Avarello";
                linkToRelease = "https://www.theflowhouse.com/inside-your-blood/";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(insideyourblood_slide1, "insideyourblood_slide1"), 
                    new ImageBean(insideyourblood_slide2, "insideyourblood_slide2"), 
                    new ImageBean(insideyourblood_slide3, "insideyourblood_slide3"), 
                    new ImageBean(insideyourblood_slide4, "insideyourblood_slide4"), 
                    new ImageBean(insideyourblood_slide5, "insideyourblood_slide5")
                ];
                firstVerticalImage = new ImageBean(insideyourblood_firstVerticalImg, "insideyourblood_vertical_01");
                secondVerticalImage = new ImageBean(insideyourblood_secondVerticalImg, "insideyourblood_vertical_02");
                horizontalImage = new ImageBean(insideyourblood_horizontalImg, "insideyourblood_horizontal");;
                scrollToViewImages = [
                    new ImageBean(insideyourblood_scrollImg0, "insideyourblood_scroll0"),
                    new ImageBean(insideyourblood_scrollImg1, "insideyourblood_scroll1"),
                    new ImageBean(insideyourblood_scrollImg2, "insideyourblood_scroll2"),
                    new ImageBean(insideyourblood_scrollImg3, "insideyourblood_scroll3"),
                    new ImageBean(insideyourblood_scrollImg4, "insideyourblood_scroll4"),
                    new ImageBean(insideyourblood_scrollImg5, "insideyourblood_scroll5"),
                    new ImageBean(insideyourblood_scrollImg6, "insideyourblood_scroll6"),
                    new ImageBean(insideyourblood_scrollImg7, "insideyourblood_scroll7"),
                    new ImageBean(insideyourblood_scrollImg8, "insideyourblood_scroll8"),
                    new ImageBean(insideyourblood_scrollImg9, "insideyourblood_scroll9")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Li Le wearing Comme des Garçons vintage skirt Comme des Garçons tank top MM6 Maison Margiela Japanese tote bag Chiara Rimondini body Chiara Rimondini trousers Chiara Rimondini sweater";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Photography Leonardo Casalini  Styling Benedetta Vangi, Jessica Cheli Model Li Le  MUA/HS Giulia Avarello  Special thanks Bilboquet ";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setLinkToRelease(linkToRelease)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build();
                break;
            } 
            case "andinvainthedreamer": { 
                location = new LocationBean("50059 Vinci, Metropolitan City of Florence", new Location("43°46'07.2\"N", "10°56'20.7\"E"))
                profileImage = new ImageBean(andinvainthedreamer_profileImageImg, "andinvainthedreamer_profile");
                title = "And in vain the Dreamer?";
                paragraphs = [
                    new ParagraphBean ("LOST", "In an abandoned place, belonging to a different past, the Dreamer, is lost, with pleasure. She imagines, observes and remembers. She creates. The search for a more vivid reality, in an amber imaginary. The lightness of the game, of a love that has been and that perhaps will never return. The dream and hope as a force or a trap."),
                    new ParagraphBean ("DREAMS", "And in vain the dreamer rakes over his old dreams, as though seeking a spark among the embers, to fan them into flame, to warm his chilled heart by the rekindled fire, and to rouse up in it again all that was so sweet, that touched his heart, that set his blood boiling, drew tears from his eyes, and so luxuriously deceived him.")
                ];
                bulletText = ["WHITENESS", "MELODY", "LUKEWARM", "JASMINE", "FRUITY"];
                credits = "Marie by Leonardo Casalini styled by Benedetta Vangi and groomed by Lisa Pascucci";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(andinvainthedreamer_slide1, "andinvainthedreamer_slide1"), 
                    new ImageBean(andinvainthedreamer_slide2, "andinvainthedreamer_slide2"), 
                    new ImageBean(andinvainthedreamer_slide3, "andinvainthedreamer_slide3"), 
                    new ImageBean(andinvainthedreamer_slide4, "andinvainthedreamer_slide4"), 
                    new ImageBean(andinvainthedreamer_slide5, "andinvainthedreamer_slide5")
                ];
                video = new VideoBean("https://player.vimeo.com/video/426574976", "https://vimeo.com/vangibenedetta/andinvainthedreamer", VideoFormat.FourByTre);
                firstVerticalImage = new ImageBean(andinvainthedreamer_firstVerticalImg, "andinvainthedreamer_vertical_01");
                secondVerticalImage = new ImageBean(andinvainthedreamer_secondVerticalImg, "andinvainthedreamer_vertical_02");
                horizontalImage = new ImageBean(andinvainthedreamer_horizontalImg, "andinvainthedreamer_horizontal");;
                scrollToViewImages = [
                    new ImageBean(andinvainthedreamer_scrollImg0, "andinvainthedreamer_scroll0"),
                    new ImageBean(andinvainthedreamer_scrollImg1, "andinvainthedreamer_scroll1"),
                    new ImageBean(andinvainthedreamer_scrollImg2, "andinvainthedreamer_scroll2"),
                    new ImageBean(andinvainthedreamer_scrollImg3, "andinvainthedreamer_scroll3"),
                    new ImageBean(andinvainthedreamer_scrollImg4, "andinvainthedreamer_scroll4"),
                    new ImageBean(andinvainthedreamer_scrollImg5, "andinvainthedreamer_scroll5"),
                    new ImageBean(andinvainthedreamer_scrollImg6, "andinvainthedreamer_scroll6"),
                    new ImageBean(andinvainthedreamer_scrollImg7, "andinvainthedreamer_scroll7")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Marie wearing Dries Van Noten dress Dries Van Noten scarf Dries Van Noten dress Céline Robert Chapeaux hat Dries Van Noten skirt Dries Van Noten shirt Dries Van Noten tank top Dries Van Noten dress";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən Photography and Video Leonardo Casalini Art Direction and Styling Benedetta Vangi Model Marie Lebedeva MUA/HS Lisa Pascucci Voice over Marie Lebedeva Special thanks Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setVideo(video)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build()
                break; 
            }
            case "genderless": { 
                location = new LocationBean("Santa Maria Novella, 50144 Florence, Metropolitan City of Florence", new Location("43°47'10.2\"N", "11°14'10.9\"E"))
                profileImage = new ImageBean(genderless_profileImageImg, "genderless_profile");
                title = "Genderless";
                paragraphs = [new ParagraphBean("DANCE", "Genderless. Neutral gender issue. Obsolete. Unrepresentative. Gender fluid. A primary social theme. Recognition. Need. Aesthetics. A voluntary expression of diversity. Resistance. Awareness of individuality. Fashion and sexuality acquire a new meaning. A protest. A dance.")];
                bulletText = ["CYAN", "CLAMOUR", "ELASTIC", "SANDALWOOD", "BITTER"];
                credits = "Beatrice and Ibrahim by Ruggero Lupo Mengoni styled by Benedetta Vangi, Jessica Cheli and Rossella Natale and groomed by Lisa Pascucci";
                linkToRelease = "https://www.kaltblut-magazine.com/genderless-by-ruggero-lupo-mengoni/";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(genderless_slide1, "genderless_slide1"), 
                    new ImageBean(genderless_slide2, "genderless_slide2"), 
                    new ImageBean(genderless_slide3, "genderless_slide3"), 
                    new ImageBean(genderless_slide4, "genderless_slide4"), 
                    new ImageBean(genderless_slide5, "genderless_slide5")
                ];
                firstVerticalImage = new ImageBean(genderless_firstVerticalImg, "genderless_vertical_01");
                secondVerticalImage = new ImageBean(genderless_secondVerticalImg, "genderless_vertical_02");
                horizontalImage = new ImageBean(genderless_horizontalImg, "genderless_horizontal");;
                scrollToViewImages = [
                    new ImageBean(genderless_scrollImg0, "genderless_scroll0"),
                    new ImageBean(genderless_scrollImg1, "genderless_scroll1"),
                    new ImageBean(genderless_scrollImg2, "genderless_scroll2"),
                    new ImageBean(genderless_scrollImg3, "genderless_scroll3"),
                    new ImageBean(genderless_scrollImg4, "genderless_scroll4"),
                    new ImageBean(genderless_scrollImg5, "genderless_scroll5"),
                    new ImageBean(genderless_scrollImg6, "genderless_scroll6"),
                    new ImageBean(genderless_scrollImg7, "genderless_scroll7"),
                    new ImageBean(genderless_scrollImg8, "genderless_scroll8"),
                    new ImageBean(genderless_scrollImg9, "genderless_scroll9"),
                    new ImageBean(genderless_scrollImg10, "genderless_scroll10"),
                    new ImageBean(genderless_scrollImg11, "genderless_scroll11")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Beatrice wearing Noir Kei Ninomiya tank Yojiro Kake coat Ibrahim wearing Yohji Yamamoto smoking jacket Comme des Garçons vintage skirt Comme des Garçons necklace Beatrice wearing Comme des Garçons vintage top Beatrice wearing Yohji Yamamoto shirt Yojiro Kake trench Ibrahim wearing Comme des Garçons shirt Comme des Garçons coat Beatrice and Ibrahim wearing Yohji Yamamoto trench Comme des Garçons skirt Junya Watanabe jackets Junya Watanabe trousers Beatrice wearing Junya Watanabe jacket Junya Watanabe skirt Ibrahim wearing Junya Watanabe trench";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Photography Ruggero Lupo Mengoni Styling Benedetta Vangi, Jessica Cheli and Rossella Natale Model Beatrice Savini and Ibrahim Diaw MUA/HS Lisa Pascucci Special thanks Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setLinkToRelease(linkToRelease)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build()
                break; 
            } 
            case "sognolucidoricercaestetica": { 
                location = new LocationBean("Via della Libertà, 50059 Vinci FI", new Location("43°46'27.0\"N", "10°57'09.7\"E"))
                profileImage = new ImageBean(sognolucido_profileImageImg, "sognolucidoricercaestetica_profile");
                title = "Sogno Lucido. Ricerca Estetica";
                paragraphs = [new ParagraphBean("DREAM", "Deep introspection and the search for being reveal a new reality. Aesthetics. Exteriority. Handling. Hypocrisy and authenticity. Vanity. Empty. Pleasure and frustration. Need. Lucid dream. Aesthetic research")];
                bulletText = ["FADED", "WHISPER", "DUCTILE", "SPICE", "BRACKISH"];
                credits = "Li by Leonardo Casalini styled by Benedetta Vangi";
                linkToRelease = "https://fashionfilmfestivalmilano.com/marangoni-the-gaze/";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(sognolucido_slide1, "sognolucidoricercaestetica_slide1"), 
                    new ImageBean(sognolucido_slide2, "sognolucidoricercaestetica_slide2"), 
                    new ImageBean(sognolucido_slide3, "sognolucidoricercaestetica_slide3"), 
                    new ImageBean(sognolucido_slide4, "sognolucidoricercaestetica_slide4"), 
                    new ImageBean(sognolucido_slide5, "sognolucidoricercaestetica_slide5")
                ];
                video = new VideoBean("https://player.vimeo.com/video/426560571", "https://vimeo.com/vangibenedetta/sognolucidoricercaestetica", VideoFormat.SixteenByNine);
                firstVerticalImage = new ImageBean(sognolucido_firstVerticalImg, "sognolucidoricercaestetica_vertical_01");
                secondVerticalImage = new ImageBean(sognolucido_secondVerticalImg, "sognolucidoricercaestetica_vertical_02");
                horizontalImage = new ImageBean(sognolucido_horizontalImg, "sognolucidoricercaestetica_horizontal");;
                scrollToViewImages = [
                    new ImageBean(sognolucido_scrollImg0, "ognolucidoricercaestetica_scroll0"),
                    new ImageBean(sognolucido_scrollImg1, "sognolucidoricercaestetica_scroll1"),
                    new ImageBean(sognolucido_scrollImg2, "sognolucidoricercaestetica_scroll2"),
                    new ImageBean(sognolucido_scrollImg3, "sognolucidoricercaestetica_scroll3"),
                    new ImageBean(sognolucido_scrollImg4, "sognolucidoricercaestetica_scroll4"),
                    new ImageBean(sognolucido_scrollImg5, "sognolucidoricercaestetica_scroll5"),
                    new ImageBean(sognolucido_scrollImg6, "sognolucidoricercaestetica_scroll6"),
                    new ImageBean(sognolucido_scrollImg7, "sognolucidoricercaestetica_scroll7")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Li Le wearing Pleats Please by Issey Miyake trousers Comme des Garçons shirt Comme des Garçons leather jacket MM6 Maison Margiela trousers Issey Miyake jacket Issey Miyake trousers Daniela Bizzi vintage skirt Issey Miyake bag ";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Video and Photography Leonardo Casalini Art Direction and Styling Benedetta Vangi Special FX Gianmarco Cavalier Model Li Chen Voice over Giulia Evelina Cowie, Li Chen, Matilde Innocenti, Samuele Picchi Special thanks Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setLinkToRelease(linkToRelease)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setVideo(video)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build()
                break; 
            }

            case "digginglight": { 
                location = new LocationBean("SR 222 Chiantigiana, 307-299, 50012 Grassina FI", new Location("43°43'20.2\"N", "11°17'43.7\"E"));
                profileImage = new ImageBean(digginglight_profileImageImg, "digginglight_profile");
                title = "Digging light";
                paragraphs = [
                    new ParagraphBean("MAN", "Man, lost, thrown into an inexplicable immensity. Stunned, asleep. Lost in the light, in a light that digs the earth."),
                    new ParagraphBean("LIFE", "Sweetness, fear. Taking advantage of fleeting moments of pleasure. incomprehensible pleasure, in a sad wandering, between veiled beauties and sometimes revealed: Life."),
                    new ParagraphBean("SKY", "Wonderful, sublime, but not definable. Blurred. Painful. Earth, sky: in a precise design, made of symbols,shades, details."),
                    new ParagraphBean("ABSOLUTE", "On a journey of purification, reflections of a perceptible but nameless elsewhere. Looking for a mission: unknown but absolute."),
                ];
                bulletText = ["GLARE", "RUSTLE", "BURNT", "MUSKY", "UNRIPE"];
                linkToRelease = "https://www.thekunstmagazine.com/blog/leonardo-casalini";
                credits = "Evans by Leonardo Casalini styled by Benedetta Vangi";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(digginglight_slide1, "digginglight_slide1"), 
                    new ImageBean(digginglight_slide2, "digginglight_slide2"), 
                    new ImageBean(digginglight_slide3, "digginglight_slide3"), 
                    new ImageBean(digginglight_slide4, "digginglight_slide4"), 
                    new ImageBean(digginglight_slide5, "digginglight_slide5")
                ];
                firstVerticalImage = new ImageBean(digginglight_firstVerticalImg, "digginglight_vertical_01");
                secondVerticalImage = new ImageBean(digginglight_secondVerticalImg, "digginglight_vertical_02");
                horizontalImage = new ImageBean(digginglight_horizontalImg, "digginglight_horizontal");;
                scrollToViewImages = [
                    new ImageBean(digginglight_scrollImg0, "digginglight_scroll0"),
                    new ImageBean(digginglight_scrollImg1, "digginglight_scroll1"),
                    new ImageBean(digginglight_scrollImg2, "digginglight_scroll2"),
                    new ImageBean(digginglight_scrollImg3, "digginglight_scroll3"),
                    new ImageBean(digginglight_scrollImg4, "digginglight_scroll4"),
                    new ImageBean(digginglight_scrollImg5, "digginglight_scroll5"),
                    new ImageBean(digginglight_scrollImg6, "digginglight_scroll6"),
                    new ImageBean(digginglight_scrollImg7, "digginglight_scroll7"),
                    new ImageBean(digginglight_scrollImg8, "digginglight_scroll8"),
                    new ImageBean(digginglight_scrollImg9, "digginglight_scroll9")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Evans wearing MM6 Maison Margiela coat MM6 Maison Margiela skirt MM6 Maison Margiela pouch bag Dries Van Noten suit Dries Van Noten shirt Junya Watanabe trench coat MM6 Maison Margiela sweater MM6 Maison Margiela vest MM6 Maison Margiela trousers";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Photography Leonardo Casalini  Styling Benedetta Vangi Model Evans Osaghae  Special thanks Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setLinkToRelease(linkToRelease)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build()
                break; 
            }
            case "tearsofhair": { 
                location = new LocationBean("Santa Maria Novella, 50144 Florence, Metropolitan City of Florence", new Location("43°47'10.2\"N", "11°14'10.9\"E"))
                profileImage = new ImageBean(tearsofhair_profileImageImg, "tearsofhair_profile");
                title = "Tears of hair";
                paragraphs = [new ParagraphBean("TEARS", "They come down like tears, as in the darkest dreams. Glowing, dropping. Eyes in flood, tears of hair. Manipulate, distorted: warped but authentic.")];
                bulletText = ["REFLECTED", "DIN", "IMPALPABLE", "OIL", "LIQUORICE"];
                credits = "Li by Ruggero Lupo Mengoni styled by Benedetta Vangi and groomed by Camilla Cantini";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(tearsofhair_slide1, "tearsofhair_slide1"), 
                    new ImageBean(tearsofhair_slide2, "tearsofhair_slide2"), 
                    new ImageBean(tearsofhair_slide3, "tearsofhair_slide3"), 
                    new ImageBean(tearsofhair_slide4, "tearsofhair_slide4"), 
                    new ImageBean(tearsofhair_slide5, "tearsofhair_slide5")
                ];
                video = new VideoBean("https://player.vimeo.com/video/428849772", "https://vimeo.com/vangibenedetta/tearsofhair", VideoFormat.SixteenByNine);
                firstVerticalImage = new ImageBean(tearsofhair_firstVerticalImg, "tearsofhair_vertical_01");
                secondVerticalImage = new ImageBean(tearsofhair_secondVerticalImg, "tearsofhair_vertical_02");
                horizontalImage = new ImageBean(tearsofhair_horizontalImg, "tearsofhair_horizontal");;
                scrollToViewImages = [
                    new ImageBean(tearsofhair_scrollImg0, "tearsofhair_scroll0"),
                    new ImageBean(tearsofhair_scrollImg1, "tearsofhair_scroll1"),
                    new ImageBean(tearsofhair_scrollImg2, "tearsofhair_scroll2"),
                    new ImageBean(tearsofhair_scrollImg3, "tearsofhair_scroll3"),
                    new ImageBean(tearsofhair_scrollImg4, "tearsofhair_scroll4"),
                    new ImageBean(tearsofhair_scrollImg5, "tearsofhair_scroll5")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Li wearing Comme des Garçons shirt Comme des Garçons jacket Comme des Garçons trousers  Noir Kei Ninomiya jacket Comme des Garçons shirt Comme des Garçons trousers Comme des Garçons shirt Noir Kei Ninomiya shirt Issey Miyake trousers";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Photography Ruggero Lupo Mengoni Styling Benedetta Vangi Model Li Chen MUA/HS Camilla Cantini Special thanks Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setVideo(video)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build();
                break;
            }
            case "crisalide": { 
                location = new LocationBean("Via della Libertà, 50059 Vinci FI", new Location("43°46'27.0\"N", "10°57'09.7\"E"));
                profileImage = new ImageBean(crisalide_profileImageImg, "crisalide_profile");
                title = "Crisalide";
                paragraphs = [
                    new ParagraphBean("ERA", "The electric soul is hibernating. Shocked, In crisis. Torpid, astonished. Evolving: Chrysalis of a new era. Undressing, will spread the wings of its beauty. Unique. Charming."),
                    new ParagraphBean("MOON", "Metamorphosis of refractions, in moon reflections.")
                ];
                bulletText = ["BRILLIANCE", "SCREECHING", "STONY", "CITRUSY", "BITTERSWEET"];
                credits = "Sara by Leonardo Casalini styled by Benedetta Vangi";
                linkToRelease = "https://contributormagazine.com/fashion-story-a-whisper-from-the-light/";
                horizontalScrollImages = [
                    slide0ImageBean, 
                    new ImageBean(crisalide_slide1, "crisalide_slide1"), 
                    new ImageBean(crisalide_slide2, "crisalide_slide2"), 
                    new ImageBean(crisalide_slide3, "crisalide_slide3"), 
                    new ImageBean(crisalide_slide4, "crisalide_slide4"), 
                    new ImageBean(crisalide_slide5, "crisalide_slide5")
                ];
                video = new VideoBean("https://player.vimeo.com/video/426570792", "https://vimeo.com/vangibenedetta/crisalide", VideoFormat.FourByTre);
                firstVerticalImage = new ImageBean(crisalide_firstVerticalImg, "crisalide_vertical_01");
                secondVerticalImage = new ImageBean(crisalide_secondVerticalImg, "crisalide_vertical_02");
                horizontalImage = new ImageBean(crisalide_horizontalImg, "crisalide_horizontal");;
                scrollToViewImages = [
                    new ImageBean(crisalide_scrollImg0, "crisalide_scroll0"),
                    new ImageBean(crisalide_scrollImg1, "crisalide_scroll1"),
                    new ImageBean(crisalide_scrollImg2, "crisalide_scroll2"),
                    new ImageBean(crisalide_scrollImg3, "crisalide_scroll3"),
                    new ImageBean(crisalide_scrollImg4, "crisalide_scroll4"),
                    new ImageBean(crisalide_scrollImg5, "crisalide_scroll5"),
                    new ImageBean(crisalide_scrollImg6, "crisalide_scroll6"),
                    new ImageBean(crisalide_scrollImg7, "crisalide_scroll7"),
                    new ImageBean(crisalide_scrollImg8, "crisalide_scroll8"),
                    new ImageBean(crisalide_scrollImg9, "crisalide_scroll9")
                ];
                fashion = "Fashion // fash·​ion \\ ˈfa-shən  \\ Sara wearing Boboutic jumpsuit Boboutic sweater Twinset trousers Boboutic coat Boboutic sweater Boboutic trousers Boboutic coat";
                production = "Production // pro·​duc·​tion | \\ prə-ˈdək-shən  , prō- \\ Photography and Video Leonardo Casalini Art Direction and Styling Benedetta Vangi Model Sara Panelli Set design Tamara Pasquinucci Special thanks Boboutic and Bilboquet";
                projectDetailsBean = new ProjectDetailsBuilder(projectBean)
                    .setLocation(location)
                    .setProfileImage(profileImage)
                    .setTitle(title)
                    .setParagraphs(paragraphs)
                    .setBulletText(bulletText)
                    .setCredits(credits)
                    .setLinkToRelease(linkToRelease)
                    .setHorizontalScrollImages(horizontalScrollImages)
                    .setVideo(video)
                    .setFirstVerticalImage(firstVerticalImage)
                    .setSecondVerticalImage(secondVerticalImage)
                    .setHorizontalImage(horizontalImage)
                    .setFashion(fashion)
                    .settscrollToViewImages(scrollToViewImages)
                    .setProduction(production)
                    .build()
                break; 
            }
            // case "naturalselection": { 
            //     location = new LocationBean("Livorno", new Location("12", "45"));
            //     projectDetailsBean = new ProjectDetailsBuilder(projectBean).setLocation(location).build();
            //     break; 
            // } 
            default: { 
                projectDetailsBean = undefined;
                break; 
            }
        }
        return projectDetailsBean;
    }

    public getProjectsSize() : number {
        return this.projectsMap.size;
    }

    private getSortedArrayKeys() : string[] {
        let projectsArray : string[] = Array.from(this.projectsMap.keys());
        projectsArray.sort( (a,b) => {
            let valueItemB = this.projectsMap.get(b) as ProjectBean;
            let valueItemA = this.projectsMap.get(a) as ProjectBean;
            return valueItemB.getProjectDate.getTime() - valueItemA.getProjectDate.getTime();
        });
        return projectsArray;
    }

    public getProjectIndex(projectId : string) : number {
        let projectArray : string[] = this.getSortedArrayKeys();
        return projectArray.findIndex( (value, index) => (value === projectId) );
    }

    public getLinkPreviousProject(projectId : string) : string | undefined {
        let projectArray : string[] = this.getSortedArrayKeys();
        let idx = projectArray.findIndex( (value, index) => (value === projectId) );
        return (idx === -1 || (idx - 1) < 0) ? undefined : projectArray[idx - 1];
    }

    public getLinkNextProject(projectId : string) : string | undefined {
        let projectArray : string[] = this.getSortedArrayKeys();
        let idx = projectArray.findIndex( (value, index) => (value === projectId) );
        return (idx === -1 || (idx + 1 > (projectArray.length-1))) ? undefined : projectArray[idx + 1];
    }
}