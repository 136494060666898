import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';

interface WorkInProgressProps {}

interface WorkInProgressState {
    emailValue : string
}

class WorkInProgress extends React.Component<WorkInProgressProps, WorkInProgressState> {

    constructor(props) {
        super(props);
        this.state = {
            emailValue : ""
        }
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        $(document).ready(function(){
            $('<script/>',{type:'text/javascript', src:'//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'}).appendTo('body');

            let fnames : string[] = []; 
            let ftypes : string[] = [];
            fnames[0]='EMAIL';
            fnames[1]='FNAME';
            fnames[2]='LNAME';
            fnames[3]='ADDRESS';
            fnames[4]='PHONE';
            fnames[5]='BIRTHDAY';
            ftypes[1]='text';
            ftypes[2]='text';
            ftypes[0]='email';
            ftypes[3]='address';
            ftypes[4]='phone';
            ftypes[5]='birthday';
            var $mcj = jQuery.noConflict(true);
        }); 
    }
  
    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={1} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"workinprogress"} />
                        </Col>
                        <Col xs={10} sm={8} className="work-in-progress-central-column">
                            <div>
                                <h5>work in progress</h5>
                            </div>
                            <div>
                                <h5>STAY IN THE LOOP</h5>
                            </div>
                            <div id="mc_embed_signup">
                                <form action="https://gmail.us19.list-manage.com/subscribe/post?u=abec46a035f4d940a12af64c8&amp;id=2bdf049de7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                    <div id="mc_embed_signup_scroll">
                                        <div className="indicates-required">
                                            <span className="asterisk">*</span> indicates required
                                        </div>
                                        <div className="mc-field-group">
                                            <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" 
                                                value={this.state.emailValue} onChange={ (e)=>{ this.setState({emailValue: e.target.value}) } }/></div>
                                        <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                                            <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                                        </div>
                                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_abec46a035f4d940a12af64c8_2bdf049de7" tabIndex={-1} defaultValue="" /></div>
                                        <div className="clear">
                                            <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="work-in-progress-code">
                                <p>
                                    if (userEmailAdded()) &#123;
                                    <br />
                                        &emsp; subscribeUser();
                                        <br />
                                        &emsp; sendUserConfirmation();
                                    <br />
                                    &#125; else &#123;
                                    <br />
                                        &emsp; waitUntilUserEmailAdded();
                                    <br />
                                    &#125;
                                </p>
                            </div>
                        </Col>
                        <Col xs={1} sm={2}>
                            <MenuComponent isMobile={false}/>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {WorkInProgress};