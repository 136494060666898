import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import { IChapterServiceManager } from '../../services/chapterServiceManager';
import { ChapterBean } from '../../beans/chapterBean';
import { ArrowChangeComponent } from '../../common/visual/arrowChangeComponent';
import { isMobileOnly } from 'react-device-detect';
import { homePath } from '../../utils/homePath';

interface ChapterComponentProps {
    chapterService : IChapterServiceManager;
}

interface ChapterComponentState {
    chapter : ChapterBean | undefined | null;
}

class Chapter extends React.Component<ChapterComponentProps & RouteComponentProps, ChapterComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            chapter: null
        }
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        const { chapterId } = this.props.match.params as any;
        let prevChapter = this.state.chapter;
        let actualChapter = this.props.chapterService.loadSingleChapter(chapterId);
        this.setState({chapter: actualChapter});
        if (prevChapter === null && actualChapter === undefined) {
            window.location.replace(homePath + "/not-found");
        }
        console.log("chapter prev: " + prevChapter + " - chapter: " + actualChapter);
    }

    renderRows() {
        let rows : any[] = [];
        let paragraphs = this.state.chapter?.getParagraphs;
        if (paragraphs !== undefined) {
            for (let i = 0; i < paragraphs.length; i++) {
                if (i % 2 === 0) {
                    let sliced = paragraphs.slice(i, i+2);
                    rows.push(
                        <Row key={i} className="two-elems-row">
                            {sliced.map( (item, index) => {
                                if (index % 2 === 0 || isMobileOnly) {
                                    return this.getEvenElement(item.getTitle, item.getText, index);
                                } else if (index % 2 === 1){
                                    return this.getOddElement(item.getTitle, item.getText, index);
                                } else {
                                    return null;
                                }
                            })}
                        </Row>
                    );
                }
            }
        }
        return rows;
    }

    getEvenElement(title, text, index) {
        return (
            <Col key={index} xs={12} md={6}>
                <Row>
                    <Col xs={3} md={4} className="chapter-description-title left">
                        <p>{title}</p>
                    </Col>
                    <Col xs={9} md={8} className="chapter-description-text left">
                        <p>{text}</p>
                    </Col>
                </Row>
            </Col>
        )
    }

    getOddElement(title, text, index) {
        return (
            <Col key={index} xs={12} md={6}>
                <Row>
                    <Col xs={9} md={8} className="chapter-description-text right">
                        <p>{text}</p>
                    </Col>
                    <Col xs={3} md={4} className="chapter-description-title right">
                        <p>{title}</p>
                    </Col>
                </Row>
            </Col>
        )
    }

    render() {

        let linkPreviousChapter = this.props.chapterService.getLinkPreviousChapter(this.state.chapter?.getChapterId as string);
        let linkNextChapter = this.props.chapterService.getLinkNextChapter(this.state.chapter?.getChapterId as string);

        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                        <Col xs={2} sm={2} className="col-social-container">
                            <SocialIconsComponent actualPage={"chapter"} />
                        </Col>
                        <Col xs={10} sm={8} className="chapter-central-column">
                            <Container fluid>
                                <Row className="chapter-first-row">
                                    <Col xs={5} className="chapter-code-col">
                                        <p>&gt;&nbsp;{this.props.location.pathname.replace(/\//, '')}</p>
                                    </Col>
                                    <Col xs={7} className="chapter-arrowchange-col">
                                        <ArrowChangeComponent 
                                            linkToPrevious={(linkPreviousChapter !== undefined && linkPreviousChapter !== "") ? "/philosophy/" + linkPreviousChapter : ""} 
                                            linkToNext={(linkNextChapter !== undefined && linkNextChapter !== "") ? "/philosophy/" + linkNextChapter : ""} 
                                            actualIndex={this.props.chapterService.getChapterIndex(this.state.chapter?.getChapterId as string) + 1} 
                                            totalElement={this.props.chapterService.getChaptersSize()}
                                            style={{}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} className="chapter-title-col">
                                        <Row>
                                            <h5>{this.state.chapter?.getTitle as string}</h5>
                                        </Row>
                                    </Col>
                                    <Col xs={6}></Col>
                                </Row>
                                <Row className="chapter-paragraphs-row">
                                    { this.renderRows() }
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={0} sm={2}>
                            <MenuComponent isMobile={false}/>
                        </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Chapter};