import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { MenuComponent } from '../../common/menu/menuComponent';
import { HeaderComponent } from '../../common/header/headerComponent';
import { FooterComponent } from '../../common/footer/footerComponent';
import { SocialIconsComponent } from '../../common/social/SocialIconsComponent';
import { ProjectComponent } from './projectComponent';
import { IProjectServiceManager } from '../../services/projectServiceManager';

interface ProjectProps {
    projectService : IProjectServiceManager;
}

interface ProjectState {
    projectId : string;
    location : string;
}

class Project extends React.Component<ProjectProps & RouteComponentProps, ProjectState> {

    constructor(props) {
        super(props);
        this.state = {
            projectId: "",
            location: ""
        }
    }
    
    componentDidMount() {
        const { projectId } = this.props.match.params as any;
        this.setState({projectId: projectId});
        this.setState({location: this.props.location.pathname});
        window.scrollTo(0, 0);
    }
  
    render() {
        return (
            <div>
                <MenuComponent isMobile={true} />
                <HeaderComponent />
                <Container fluid>
                    <Row>
                    <Col xs={2} sm={2} className="col-social-container">
                        <SocialIconsComponent actualPage={"project"} />
                    </Col>
                    <Col xs={10} sm={8}>
                        <ProjectComponent
                            projectService={this.props.projectService}
                            projectId={this.state.projectId}
                            location={this.state.location}
                        />
                    </Col>
                    <Col xs={0} sm={2}>
                        <MenuComponent isMobile={false} />
                    </Col>
                    </Row>
                </Container>
                <FooterComponent />
            </div>
        )
    }
}

export {Project};